<template>
  <div
    class="driver_report_slider"
    :class="{ 'driver_report_slider--disabled': disabled }"
  >
    <template v-if="photos.length > 1">
      <swiper
        :slides-per-view="slidesPerView"
        :space-between="spaceBetween"
        class="driver_report_slider__swiper"
        :breakpoints="computedBreakpoints"
      >
        <swiper-slide
          v-for="photo in photos"
          :key="photo.id"
          class="driver_report_slider__slide"
          @click="photoPopup(photo.id)"
        >
          <div class="driver_report_slider__slide__photo__wrapper">
            <img
              :src="photo.path"
              class="driver_report_slider__slide__photo"
              :alt="'driver report img ' + photo.id"
            >
            <div class="driver_report_slider__slide__photo__title__wrapper">
              <span class="driver_report_slider__slide__photo__title">{{ photoTexts[photo.type] }}</span>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </template>
    <template v-else>
      <img class="single-photo" :src="photos[0].path" alt="" @click="photoPopup(photos[0].id)">
      <div class="driver_report_slider__slide__photo__title__wrapper">
        <span class="driver_report_slider__slide__photo__title">{{ photoTexts[photos[0].type] }}</span>
      </div>
    </template>
  </div>
  <photo-modal
    :id="modalPhotoId"
    :is-visible="modalPhotoShow"
    :photos="photos"
    :texts="photoTexts"
    @close="modalPhotoShow = false"
  >
  </photo-modal>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import PhotoModal from './photo-modal.vue'

export default {
  name: 'photo-slider',
  components: {
    PhotoModal,
    Swiper,
    SwiperSlide,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    photos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modalPhotoShow: false,
      modalPhotoId: 0,
      slidesPerView: 2.5,
      photoTexts: {
        package: 'Package',
        fridge: 'Fridge',
        driver: 'Building/House ',
        copay: 'Check or cash',
        apt: 'Apartment/Front Door',
        id: false,
      },
    }
  },
  computed: {
    computedBreakpoints() {
      if (this.photos.length === 2) {
        return {
          450: {
            slidesPerView: 3,
          },
        }
      }

      if (this.photos.length > 2) {
        return {
          450: {
            slidesPerView: 3,
          },
          720: {
            slidesPerView: 4,
          },
        }
      }

      return {}
    },
    spaceBetween() {
      return this.photos.length > 2 ? 10 : 5
    },
  },
  methods: {
    photoPopup(id) {
      this.modalPhotoId = Number(id)
      this.modalPhotoShow = true
    },
  },
}
</script>

<style scoped>
.single-photo {
  width: 100%;
  max-width: 200px;
  border: 1px solid #21979E;
  border-radius: 15px;
}

.modal-photo {
  width: 100%;
}

.driver_report_slider__slide__photo {
  width: 100%;
}

.driver_report_slider__slide__photo__wrapper {
  width: 100%;
}
</style>
