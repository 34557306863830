<template>
  <delivering-view v-if="deliveringState.state === 'confirmed-delivering'"></delivering-view>
  <delivering-view v-if="deliveringState.state === 'non-confirmed-delivering'"></delivering-view>
  <delivering-view v-if="deliveringState.state === 'confirmed-no-route'"></delivering-view>
  <delivered-view v-if="deliveringState.state === 'delivered'"></delivered-view>
  <rejected-view v-if="deliveringState.state === 'canceled'"></rejected-view>
  <need-reschedule v-if="deliveringState.state === 'need-reschedule'"></need-reschedule>
  <rescheduled-component v-if="deliveringState.state === 'rescheduled'"></rescheduled-component>
  <returned-component v-if="deliveringState.state === 'returned'"></returned-component>
  <investigation-view v-if="deliveringState.state === 'investigation'"></investigation-view>
</template>

<script>
import { mapGetters } from 'vuex'
import DeliveringView from '@/components/MainPage/Delivering/index.vue'
import DeliveredView from '@/components/MainPage/Delivered/index.vue'
import RejectedView from '@/components/MainPage/Rejected/index.vue'
import NeedReschedule from '@/components/MainPage/NeedReschedule/need-reschedule.vue'
import RescheduledComponent from '@/components/MainPage/Delivering/RescheduledComponent/index.vue'
import ReturnedComponent from '@/components/MainPage/Returned/index.vue'
import InvestigationView from '@/components/MainPage/Investigations/index.vue'

export default {
  name: 'main-page',
  components: {
    InvestigationView,
    ReturnedComponent,
    RescheduledComponent,
    NeedReschedule,
    RejectedView,
    DeliveredView,
    DeliveringView,
  },
  computed: {
    ...mapGetters(['deliveringState']),
  },
}
</script>
