<template>
  <template
    v-if="!disableReschedule"
  >
    <router-link
      :to="{
        name: 'reschedule_page',
        query: {
          [urlParams[0]]: urlParams[1]
        }
      }"
      class="btn btn_address"
    >
      <svg width="15" height="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:style="'width: 15px'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_1824_15031)" fill="#111"><path d="M12 2.332a1.188 1.188 0 00-.352-.832L10.523.375a1.199 1.199 0 00-1.7 0L.462 8.71a1.2 1.2 0 00-.348.788L0 11.579a.405.405 0 00.42.42l2.085-.104a1.2 1.2 0 00.791-.349l8.352-8.366A1.191 1.191 0 0012 2.332zM2.73 10.98a.4.4 0 01-.262.116l-1.643.082.086-1.642a.397.397 0 01.117-.262l6.847-6.837 1.688 1.688L2.73 10.98zm8.351-8.355l-.956.945L8.43 1.875l.945-.956a.401.401 0 01.566 0l1.125 1.125a.401.401 0 010 .566l.015.015zM11.599 11.201H4.8a.4.4 0 000 .799h6.799a.4.4 0 000-.799z"/></g><defs><clipPath id="clip0_1824_15031"><path fill="#fff" d="M0 0h12v12H0z"/></clipPath></defs></svg>
      <span style="margin-left: 10px">Reschedule/Change address</span>
    </router-link>
  </template>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'reschedule-btn',
  computed: {
    ...mapGetters(['disableReschedule', 'urlParams']),
  },
}
</script>
