<template>
  <p class="form__toggle__title">{{ text }}</p>
  <button class="tip_btn tip_btn--toggle js--btn-tip" type="button" @click="toggleTooltip">
    <i class="icon">
      <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 2.406c-4.74 0-8.594 3.856-8.594 8.594 0 4.74 3.856 8.594 8.594 8.594 4.74 0 8.594-3.856 8.594-8.594 0-4.74-3.856-8.594-8.594-8.594zm0 3.524a1.117 1.117 0 11.064 2.233A1.117 1.117 0 0111 5.93zm2.063 9.71H9.28a.688.688 0 010-1.375h1.204v-3.78h-.688a.688.688 0 110-1.375h1.375a.688.688 0 01.688.687v4.468h1.203a.687.687 0 110 1.375z" fill="#9f9d9c"/></svg>
    </i>
  </button>
  <div class="tip_help js--tip" :class="{active: isTooltipActive}">
    <h5 class="tip_help__title">{{ title }}</h5>
    <p>{{ content }}</p>
  </div>
</template>

<script>
export default {
  name: 't-tooltip',
  props: {
    text: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isTooltipActive: false,
      tooltipTimeout: null,
    }
  },
  methods: {
    toggleTooltip() {
      this.isTooltipActive = !this.isTooltipActive

      if (this.isTooltipActive) {
        this.setTooltipTimeout()
      } else {
        this.clearTooltipTimeout()
      }
    },
    setTooltipTimeout() {
      this.clearTooltipTimeout()

      this.tooltipTimeout = setTimeout(() => {
        this.isTooltipActive = false
      }, 5000)
    },
    clearTooltipTimeout() {
      if (this.tooltipTimeout) {
        clearTimeout(this.tooltipTimeout)
        this.tooltipTimeout = null
      }
    },
  },
}
</script>
