<template>
  <template v-if="activeStatus">
    <h4 class="info_title">Order status:</h4>
    <div class="order-status-block not-delivered">
      <ul class="order_status">
        <li class="order_status__item" :class="{ active: activeStatus >= 1 }">
          <i class="icon order_status__icon">
            <svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M21.635 8H9.24C8.56 8 8 8.56 8 9.24v12.395c0 .68.56 1.24 1.24 1.24h5.673a5.925 5.925 0 01-.538-2.48 6.025 6.025 0 016.02-6.02c.886 0 1.722.191 2.48.538V9.24c0-.68-.56-1.24-1.24-1.24zm-3.329 6.332a.569.569 0 01-.212.043.527.527 0 01-.361-.142l-2.296-2.139-2.294 2.14a.536.536 0 01-.574.098.537.537 0 01-.319-.488V9.063h6.375v4.78a.537.537 0 01-.319.49z"/><path d="M24.001 17.542a4.706 4.706 0 00-.75-.751 4.568 4.568 0 00-2.855-.999 4.613 4.613 0 00-4.604 4.604c0 1.077.375 2.076.999 2.855.22.276.474.531.75.75.78.624 1.778 1 2.855 1A4.613 4.613 0 0025 20.395a4.567 4.567 0 00-.999-2.854zm-3.605 6.396a3.482 3.482 0 01-2.097-.694 3.151 3.151 0 01-.75-.751 3.481 3.481 0 01-.695-2.097 3.543 3.543 0 013.542-3.541c.786 0 1.509.255 2.097.694.29.205.545.46.75.75.44.588.695 1.31.695 2.097a3.543 3.543 0 01-3.542 3.542z"/><path d="M24.001 17.54l-.758.759-4.944 4.944-.758.758a4.703 4.703 0 01-.75-.751l.757-.758 4.945-4.944.758-.758c.276.22.53.475.75.75z"/></svg>
          </i>
          <div class="order_status__title">Order <br> canceled</div>
        </li>
        <li class="order_status__item" :class="{ active: activeStatus >= 2 }">
          <i class="icon order_status__icon">
            <svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path clip-rule="evenodd" d="M19.086 9.266h1.727c.182 0 .332.149.332.332v2.257h2.257c.183 0 .332.15.332.332v1.727c0 .183-.149.332-.332.332h-2.258v2.258c0 .183-.149.332-.331.332h-1.727a.333.333 0 01-.332-.332v-2.258h-2.258a.333.333 0 01-.332-.332v-1.726c0-.183.15-.333.332-.333h2.258V9.598c0-.183.149-.332.332-.332zm-5.053 9.7a13.911 13.911 0 01-1.93-2.409.925.925 0 01.005-1.016l.787-1.214c.168-.259.2-.555.091-.844L11.634 9.88a.94.94 0 00-1.39-.463L8.356 10.62a2.323 2.323 0 00-1.024 2.533 16.71 16.71 0 004.523 7.99 16.71 16.71 0 007.99 4.523 2.323 2.323 0 002.533-1.024l1.205-1.886a.94.94 0 00-.463-1.39l-3.604-1.353a.916.916 0 00-.844.091l-1.214.787a.925.925 0 01-1.016.005c-.872-.551-1.68-1.2-2.41-1.93z"/></svg>
          </i>
          <div class="order_status__title">Confirmed back <br> to pharmacy</div>
        </li>
        <li class="order_status__item" :class="{ active: activeStatus >= 3 }">
          <i class="icon order_status__icon">
            <svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M24.469 20.219a.531.531 0 00-.532.531 3.191 3.191 0 01-3.187 3.188 3.191 3.191 0 01-3.188-3.188 3.191 3.191 0 013.188-3.188c.735 0 1.436.259 1.991.709h-.751a.531.531 0 000 1.062h1.77a.531.531 0 00.532-.53V17.03a.531.531 0 00-1.063 0v.277a4.232 4.232 0 00-2.479-.808 4.254 4.254 0 00-4.25 4.25A4.254 4.254 0 0020.75 25 4.254 4.254 0 0025 20.75a.531.531 0 00-.531-.531zM14.53 8l-3.442.014c-.446 0-.864.248-1.076.638l-1.8 3.258 5.972-.014L14.53 8zM21.94 11.881l-1.82-3.258c-.22-.39-.63-.638-1.084-.638L15.593 8l.361 3.895 5.986-.014z"/><path d="M15.083 20.75c0 .49.064.964.177 1.417l-6.12.021h-.007a1.12 1.12 0 01-1.112-1.119l-.028-8.096 6.552-.014h1.063l6.552-.021.007 2.33a5.593 5.593 0 00-1.417-.184 5.674 5.674 0 00-5.667 5.666z"/></svg>
          </i>
          <div class="order_status__title">On its way to <br> the pharmacy</div>
        </li>
        <li class="order_status__item" :class="{ active: activeStatus >= 4 }">
          <i class="icon order_status__icon">
            <svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.344 11.188H20.75v-.532a1.595 1.595 0 00-1.594-1.594h-5.312a1.595 1.595 0 00-1.594 1.594v.531h-1.594a2.127 2.127 0 00-2.125 2.126v8.5a2.125 2.125 0 002.125 2.125h11.688a2.125 2.125 0 002.125-2.125v-8.5a2.127 2.127 0 00-2.125-2.126zm-3.188 6.906h-2.125v2.125a.531.531 0 11-1.062 0v-2.125h-2.125a.531.531 0 010-1.063h2.125v-2.125a.531.531 0 111.062 0v2.125h2.125a.531.531 0 010 1.063zm.532-6.907h-6.375v-.53a.531.531 0 01.53-.532h5.313a.531.531 0 01.532.531v.531z"/></svg>
          </i>
          <div class="order_status__title">Back to <br> pharmacy</div>
        </li>
      </ul>
    </div>
  </template>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'not-delivered-order-status',
  computed: {
    ...mapGetters(['deliveringState']),
    activeStatus() {
      if (this.deliveringState.subState === 'pending-back-to-pharmacy') {
        return 1
      }

      if (this.deliveringState.subState === 'confirmed-back-to-pharmacy') {
        return 2
      }

      if (this.deliveringState.subState === 'on-its-way-to-pharmacy') {
        return 3
      }

      if (this.deliveringState.subState === 'back-to-pharmacy') {
        return 4
      }

      return 0
    },
  },
}
</script>
