const transitStatuses = [
  'ready-to-print',
  'ready-for-pickup',
  'pickup-occurred',
  'ready-for-delivery',
  'route-optimized',
  'driver-out',
]

const deliveredStatuses = [
  'delivered',
]

const canceledStatuses = [
  'delivery-attempted',
  'returned',
]

const rejectedStatuses = [
  'back-to-pharmacy',
]

export default async function setDeliveringState(orderData, routeData) {
  if (deliveredStatuses.includes(orderData.status_slug)) {
    return {
      state: 'delivered',
    }
  }

  if (orderData.status_slug === 'investigation') {
    return {
      state: 'investigation',
    }
  }

  if (orderData.confirmed === 2) {
    return {
      state: 'rescheduled',
    }
  }

  if (orderData.status_slug === 'driver-out' && orderData.visible === 0) {
    return {
      state: 'rescheduled',
    }
  }

  if (orderData.status_slug === 'rejected') {
    return {
      state: 'canceled',
      subState: 'rejected',
    }
  }

  if (rejectedStatuses.includes(orderData.status_slug)) {
    if (orderData.sub_status_slug === 'open') {
      return {
        state: 'canceled',
        subState: 'back-to-pharmacy',
      }
    }

    if (orderData.sub_status_slug === 'confirmed-back-to-pharmacy') {
      return {
        state: 'canceled',
        subState: 'back-to-pharmacy',
      }
    }

    if (orderData.sub_status_slug === 'on-its-way-to-the-pharmacy') {
      return {
        state: 'canceled',
        subState: 'back-to-pharmacy',
      }
    }
  }

  if (canceledStatuses.includes(orderData.status_slug)) {
    if (orderData.status_slug === 'returned' && orderData.sub_status_slug === 'open') {
      return {
        state: 'returned',
        subState: 'open',
      }
    }
    if (orderData.status_slug === 'returned' && orderData.sub_status_slug === 'pending-back-to-pharmacy') {
      return {
        state: 'returned',
        subState: 'pending-back-to-pharmacy',
      }
    }

    if (orderData.sub_status_slug === 'open') {
      if (orderData.activity === 'Wrong address') {
        if (orderData.scheduled === null || orderData.confirmed !== 2) {
          return {
            state: 'need-reschedule',
            subState: 'wrong-address',
          }
        }
      }

      if (orderData.activity === 'Not Present') {
        if (orderData.scheduled === null || orderData.confirmed !== 2) {
          return {
            state: 'need-reschedule',
            subState: 'not-present',
          }
        }
      }
      return {
        state: 'need-reschedule',
        subState: 'not-present',
      }
    }

    if (orderData.status_slug === 'returned' && orderData.sub_status_slug === 'pending-back-to-pharmacy') {
      return {
        state: 'non-confirmed-delivering',
      }
    }

    if (orderData.sub_status_slug === 'send-to-the-route') {
      return {
        state: 'need-reschedule',
        subState: 'not-present',
      }
    }

    if (orderData.sub_status_slug === 'pending-back-to-pharmacy') {
      return {
        state: 'canceled',
        subState: 'pending-back-to-pharmacy',
      }
    }

    if (orderData.sub_status_slug === 'confirmed-back-to-pharmacy') {
      return {
        state: 'canceled',
        subState: 'confirmed-back-to-pharmacy',
      }
    }

    if (orderData.sub_status_slug === 'on-its-way-to-the-pharmacy') {
      return {
        state: 'canceled',
        subState: 'on-its-way-to-pharmacy',
      }
    }

    if (orderData.status_slug === 'delivery-attempted') {
      if (orderData.activity === 'Wrong address') {
        if (orderData.scheduled === null || orderData.confirmed !== 2) {
          return {
            state: 'need-reschedule',
            subState: 'wrong-address',
          }
        }
      }

      if (orderData.activity === 'Not Present') {
        if (orderData.scheduled === null || orderData.confirmed !== 2) {
          return {
            state: 'need-reschedule',
            subState: 'not-present',
          }
        }
      }

      return {
        state: 'need-reschedule',
        subState: 'not-present',
      }
    }

    if (orderData.sub_status_slug === 'scheduled') {
      return {
        state: 'need-reschedule',
        subState: 'not-present',
      }
    }
  }

  if (transitStatuses.includes(orderData.status_slug)) {
    if (orderData.confirmed === 1) {
      if (routeData) {
        return {
          state: 'confirmed-delivering',
        }
      }

      return {
        state: 'confirmed-no-route',
      }
    }
    return {
      state: 'non-confirmed-delivering',
    }
  }
}
/*
* delivered
* wrong-address
* rescheduled
* need-reschedule
* pending-back-to-pharmacy (canceled)
* confirmed-back-to-pharmacy (canceled)
* on-its-way-to-pharmacy (canceled)
* back-to-pharmacy (canceled)
* confirmed-delivering
* non-confirmed-delivering !
* */
