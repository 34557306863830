<template>
  <div class="form__input" :class="{'form__input--small': small}">
    <h5 class="info_title form__input__title" :class="{'info_title--small': smallLabel}">{{ label }}</h5>
    <div class="form__input__field_wrapper">
      <input
        ref="input"
        v-model="customValue"
        v-bind="$attrs"
        class="form__input__field_text"
        :class="{'error-input' : error}"
        type="text"
        placeholder=""
      >
      <p class="form__input__placeholder">{{ placeholder }}</p>
      <slot name="button"></slot>
    </div>
  </div>
</template>
<script>
export default {
//   inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },

    smallLabel: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    customValue: {
      get() { return this.modelValue },
      set(v) { this.$emit('update:modelValue', v) },
    },
  },
}
</script>
