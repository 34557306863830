<template>
  <div class="calendar-selector" style="margin-bottom: 7px;">
    <h3>{{ currentMonth }}</h3>
    <hr>
    <div class="days-of-week">
      <div>su</div>
      <div>mo</div>
      <div>tu</div>
      <div>we</div>
      <div>th</div>
      <div>fr</div>
      <div>sa</div>
    </div>
    <div class="dates">
      <div v-for="(week, weekKey) in weeks" :key="weekKey" class="week">
        <div v-for="(date, dateKey) in week" :key="dateKey" class="date">
          <label
            :for="`date-${dateKey}-${weekKey}`"
            :class="{'active': date.available, 'checked': rescheduleData.date_to_deliver === date.date}"
          >
            {{ getDateNumber(date.date) }}
          </label>
          <input
            :id="`date-${dateKey}-${weekKey}`"
            v-model="rescheduleData.date_to_deliver"
            type="radio"
            :value="date.date"
            :disabled="!date.available"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { commUrl } from '@/config'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'calendar-selector',
  data() {
    return {
      currentMonth: moment().format('MMMM'),
      holidays: [],
      availableDays: [],
      weeks: [],
    }
  },
  computed: {
    ...mapGetters(['rescheduleData']),
  },
  async mounted() {
    moment.locale('en-us')
    const res = await axios.get(`${commUrl}get-holidays`)
    this.holidays = res.data.holidays

    this.getAvailableDays()
    this.calculateWeeks()
  },
  methods: {
    getDateNumber(date) {
      return moment(date).format('DD')
    },
    getAvailableDays() {
      const today = moment().startOf('day')
      today.add(1, 'days')
      this.availableDays = []

      for (let foundDays = 0; foundDays < 5; today.add(1, 'days')) {
        if (today.day() !== 0 && !this.holidays.includes(today.format('YYYY-MM-DD'))) {
          this.availableDays.push(today.format('YYYY-MM-DD'))
          foundDays++
        }
      }

      if (this.availableDays.length > 0) {
        this.$store.commit('setRescheduleDataParse', { date_to_deliver: this.availableDays[0] })
      }
    },
    getWeekFromDate(date) {
      return moment(date).isoWeek()
    },
    calculateWeeks() {
      this.weeks = []

      if (this.availableDays.length === 0) {
        return
      }

      let currentWeekStart = moment(this.availableDays[0]).startOf('week')

      this.availableDays.forEach(day => {
        const dayMoment = moment(day)
        if (!dayMoment.isSame(currentWeekStart, 'week')) {
          currentWeekStart = dayMoment.startOf('week')
        }
        if (!this.weeks.find(w => moment(w[0].date).isSame(currentWeekStart, 'week'))) {
          const weekEnd = currentWeekStart.clone().endOf('week')
          this.weeks.push(this.addDays(currentWeekStart, weekEnd))
        }
      })
    },
    addDays(start, end) {
      const days = []
      for (let d = moment(start); d.isSameOrBefore(end); d.add(1, 'days')) {
        let available = this.availableDays.includes(d.format('YYYY-MM-DD'))
        if (d.day() === 0) {
          available = false
        }

        days.push({
          date: d.format('YYYY-MM-DD'),
          available,
        })
      }
      return days
    },
  },
}
</script>
