<template>
  <confirmed-view v-if="deliveringState.state === 'confirmed-delivering'"></confirmed-view>
  <confirmed-no-route v-if="deliveringState.state === 'confirmed-no-route'"></confirmed-no-route>
  <not-confirmed-view v-if="deliveringState.state === 'non-confirmed-delivering'"></not-confirmed-view>
</template>
<script>
import { mapGetters } from 'vuex'
import ConfirmedView from '@/components/MainPage/Delivering/Confirmed/index.vue'
import NotConfirmedView from '@/components/MainPage/Delivering/NotConfirmed/index.vue'
import ConfirmedNoRoute from '@/components/MainPage/Delivering/ConfirmedNoRoute/index.vue'

export default {
  name: 'delivering-view',
  components: {
    ConfirmedNoRoute,
    NotConfirmedView,
    ConfirmedView,
  },
  computed: {
    ...mapGetters(['deliveringState']),
  },
}
</script>

<style lang="scss" scoped>

</style>
