<template>
  <div class="client_info__container form error">
    <div v-if="doNotDeliver" class="error-delivery">Sorry, wo do not delivery to theselected address, Please enter another address</div>
    <c-address-full
      v-model:address="computedAddress"
      :error="doNotDeliver"
      :origin="orderData.recipient.address"
      placeholder="Start typing"
      label="Full Address"
      small-label
    ></c-address-full>
    <c-input v-model="computedAddress.address" :error="doNotDeliver" label="Street Address" small-label></c-input>
    <c-input
      v-model="computedAddress.zip"
      :error="doNotDeliver"
      label="ZIP"
      small
      small-label
    ></c-input>
    <c-input
      v-model="computedAddress.city"
      :error="doNotDeliver"
      label="City"
      small
      small-label
    ></c-input>
    <c-address-state
      v-model="computedAddress.state"
      :address="computedAddress"
      :error="doNotDeliver"
      label="State"
      small
      small-label
    ></c-address-state>
    <c-input
      v-model="computedAddress.apt"
      :error="doNotDeliver"
      label="Apt"
      small
      small-label
    ></c-input>

    <!-- <p class="map__msg_error">Sorry, we do not deliver to the selected address. Enter another address or contact {{ orderData.client.business_name }} {{ orderData.client.phone }}</p> -->
    <c-map v-model:address="computedAddress" :facility-geo="orderData.facility.geo"></c-map>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import CInput from '@/components/UI/form/c-input.vue'
import { useTypes } from '@/compositions/Address'
import CMap from './address/c-map.vue'
import CAddressState from './address/c-address-state.vue'
import CAddressFull from './address/c-address-full.vue'

export default {
  name: 'change-address',
  components: {
    CMap,
    CInput,
    CAddressState,
    CAddressFull,
  },
  setup() {
    return useTypes()
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters(['rescheduleData', 'orderData']),
    computedAddress: {
      get() {
        this.defineAddress()
        return this.rescheduleData.address
      },
      set(address) { this.rescheduleData.address = address },
    },
    doNotDeliver() {
      return !this.types[this.rescheduleData.order_type]
    },
  },
  methods: {
    defineAddress() {
      if (!('address' in this.rescheduleData)) {
        this.rescheduleData.address = { ...JSON.parse(JSON.stringify(this.orderData.recipient.address)) }
      }
    },
  },
}
</script>
<style>
.error-delivery {
  font-weight: bold;
  font-size: 23px;
  color: var(--color-error);
}
</style>
