<template>
  <div v-if="isVisible" class="modal" @click="handleBackgroundClick">
    <div class="modal-content" @click="handleModalContentClick">
      <div class="modal-body">
        <p class="title">Thank you for using our service and we appreciate your feedback.</p>
        <p class="subtitle">Please leave a review on our Google page</p>
      </div>
      <div class="modal-footer">
        <t-button :class="'success'" @click="close()">Ok</t-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import TButton from '@/components/UI/t-button.vue'

export default {
  name: 'link-feedback-popup',
  components: { TButton },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  computed: {
    ...mapGetters(['orderData']),
  },
  methods: {
    handleBackgroundClick() {
      window.location.href = this.link
      this.$emit('close')
    },
    handleModalContentClick(event) {
      event.stopPropagation()
    },
    close() {
      window.location.href = this.link
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
}

.modal-footer {
  margin-top: 12px;
}

.modal-header {
  margin-bottom: 12px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.modal-body {
  text-align: center;

  .title {
    font-weight: 600;
    margin-bottom: 10px;
  }

  .subtitle {
    font-size: 18px;
    margin-bottom: 15px;
  }
}
</style>
