<template>
  <div class="client_info__container delivery__condition delivery__condition--facenr">
    <p class="icon delivery__condition__warning ">18+</p>
    <h3 class="delivery__condition__title">
      <i class="icon delivery__condition__title__icon">
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.019 6.013a2.98 2.98 0 115.96 0 2.98 2.98 0 01-5.96 0zM4.737 10.435c.807-.338 1.883-.448 3.263-.448 1.376 0 2.451.11 3.258.446.865.361 1.396.975 1.68 1.868a.512.512 0 01-.488.666H3.546a.51.51 0 01-.485-.663c.282-.893.812-1.507 1.676-1.869z" fill="#B75EAE"/></svg>
      </i>
      <span class="delivery__condition__text">Face-to-Face Signature Not Required</span>
    </h3>
    <p class="delivery__condition__desc">
      Signature is required by any 18+ Adult who lives with the patient.
      Driver will take a picture of the patient’s door.
    </p>
  </div>
</template>
<script>
export default {
  name: 'face2-face-not-required',
}
</script>
