<template>
  <div class="wrapper-section pb-10">
    <section class="section delivered-section" style="padding-top: 13px">
      <div class="section__content">
        <h2 class="section__title">Your feedback matters to us</h2>
        <p class="delivery__feedback__desc">
          {{ feedbackTitle }}
        </p>

        <ul class="feedback__mark">
          <star-rating
            v-if="orderData.driver"
            v-model="feedbackData.rate_driver"
            type="Driver"
            :photo="orderData.driver.avatar"
          ></star-rating>
          <star-rating
            v-if="orderData.dispatcher"
            v-model="feedbackData.rate_dispatcher"
            type="Dispatcher"
            :photo="orderData.dispatcher.avatar"
          ></star-rating>
          <star-rating
            v-if="orderData.client"
            v-model="feedbackData.rate_pharmacy"
            type="Pharmacy"
            :photo="orderData.client.avatar"
          ></star-rating>
        </ul>

        <feedback-suggestions
          :feedback-data="feedbackData"
          :feedback-sent="feedbackSent"
          @update-feedback="updateFeedbackData"
        ></feedback-suggestions>

        <div class="client_info__container form">
          <div class="form__input">
            <h5 class="info_title form__input__title">Please leave your comments:</h5>
            <div class="form__input__field_wrapper">
              <textarea
                v-model="feedbackData.comment"
                class="form__input__field_text form__input__field_textarea"
                resize="none"
                placeholder=" "
              ></textarea>
              <p class="form__input__placeholder">
                Write down if you liked everything, your expectations and impressions
              </p>
            </div>
          </div>
          <div v-if="!feedbackSent" class="form__toggle">
            <t-tooltip
              text="Contact me"
              title="Customer service will call you"
              content="Please leave your feedback, and our Customer Service Agent will contact you shortly."
            ></t-tooltip>
            <div class="form__toggle__input__wrapper">
              <input
                v-model="feedbackData.contact_me"
                type="checkbox"
                class="form__toggle__input"
              >
              <div class="form__toggle__input__checkmark"></div>
            </div>
          </div>

          <t-button
            v-if="!feedbackSent"
            :class="'success'"
            :disabled="!feedbackValidated"
            style="margin-top: 10px"
            @click="sendFeedback()"
          >
            <i class="icon form__btn__icon form__btn__icon--tg">
              <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19.324.677a1.031 1.031 0 00-1.096-.235L1.04 6.948h-.005a1.031 1.031 0 00.043 1.941l.018.005 5.9 2.52a.687.687 0 00.667-.155l9.469-8.822a.304.304 0 11.43.43L8.737 12.33a.688.688 0 00-.154.668l2.52 5.904.008.024c.137.398.486.679.906.698h.043a1.058 1.058 0 00.989-.665l6.504-17.183a1.031 1.031 0 00-.23-1.1v.001z" fill="#fff"/></svg>
            </i>
            <span>Send feedback</span>
          </t-button>

          <t-button
            v-if="feedbackSent"
            :class="'success'"
            :disabled="!feedbackUpdateValidated"
            style="margin-top: 10px"
            @click="sendFeedback()"
          >
            <span>Change feedback</span>
          </t-button>
          <link-feedback-popup
            :is-visible="showAllFive"
            :link="link"
            @close="showAllFive = false"
          ></link-feedback-popup>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'

import { mapGetters } from 'vuex'
import _ from 'lodash'
import TButton from '@/components/UI/t-button.vue'
import TTooltip from '@/components/UI/t-tooltip.vue'
import { getFeedback, storeFeedback } from '@/services/feedbackService'
import LinkFeedbackPopup from '@/components/MainPage/Delivered/components/link-feedback-popup.vue'
import FeedbackSuggestions from './feedback-suggestions.vue'
import StarRating from './star-rating.vue'

export default {
  name: 'delivered-feedback',
  components: {
    LinkFeedbackPopup,
    FeedbackSuggestions,
    TTooltip,
    TButton,
    StarRating,
  },
  data() {
    return {
      allFiveFeedback: false,
      showAllFive: false,
      feedbackSent: false,
      feedbackData: {},
      rateDispatcherChanged: false,
      ratePharmacyChanged: false,
      titles: {
        0: 'Our executive team reviews every comment',
        1: 'We apologize for the inconvenience caused. Please reach out to us 929 7441114 so we can immediately address the issue!',
        2: 'We’re so sorry for the inconvenience this may caused. Please reach us 929 7441114 so we can take care of you the way we should.',
        3: 'We’re so sorry for the inconvenience this may caused. Please reach us 929 7441114 so we can take care of you the way we should.',
        4: 'Please give us a call, we want to make it exceptional!',
        5: 'We are delighted that you had an exceptional experience with us, tell us what you liked the most!',
      },
    }
  },
  computed: {
    ...mapGetters(['orderData', 'feedback']),
    link() {
      if (this.orderData?.client?.customize?.feedback_google) {
        return this.orderData?.client?.customize?.feedback_google
      }
      return 'https://g.page/r/CadFEDwbi9npEAo/review'
    },
    feedbackTitle() {
      const feedbackChanged = this.feedbackData.rate_pharmacy || this.feedbackData.rate_dispatcher || this.feedbackData.rate_driver
      if (feedbackChanged === 0) {
        return this.titles[0]
      }

      const rates = [this.feedbackData.rate_pharmacy, this.feedbackData.rate_dispatcher, this.feedbackData.rate_driver]
      const validRates = rates.filter(rate => typeof rate === 'number' && rate > 0)

      if (validRates.length === 0) {
        return this.titles[0]
      }

      const minValue = Math.min(...validRates)

      if (minValue <= 2) {
        const updatedFeedback = this.feedbackData
        updatedFeedback.contact_me = true
        this.updateFeedbackData(updatedFeedback)
      }

      if (minValue > 4) {
        this.setAllFive(true)
      }

      return this.titles[minValue]
    },
    feedbackValidated() {
      return this.feedbackData.rate_driver !== 0 || this.feedbackData.rate_dispatcher !== 0 || this.feedbackData.rate_pharmacy !== 0
    },
    feedbackUpdateValidated() {
      return !_.isEqual(this.feedbackData, this.feedback)
    },
  },
  async mounted() {
    const { feedback } = await getFeedback(this.orderData.uid)
    if (feedback) {
      this.feedbackSent = true
      this.$store.commit('setFeedback', feedback)
    }

    this.feedbackData = _.cloneDeep(this.feedback)
  },
  methods: {
    setAllFive(isAllFive) {
      this.allFiveFeedback = isAllFive
    },
    async sendFeedback() {
      this.$store.commit('setLoaderVisible', true)
      this.feedbackData.order_uid = this.orderData.uid
      if (this.orderData.driver) {
        this.feedbackData.driver_uid = this.orderData.driver.uid
      }
      if (this.orderData.dispatcher) {
        this.feedbackData.dispatcher_uid = this.orderData.dispatcher.uid
      }
      this.feedbackData.client_uid = this.orderData.client.uid
      this.feedbackData.useragent = navigator.userAgent
      this.feedbackData.facility = this.orderData.facility.uid

      const response = await storeFeedback(this.feedbackData)

      this.feedbackSent = true

      this.feedback = this.feedbackData

      const toast = useToast()

      if (response.success) {
        toast.success(response.success)
        if (this.allFiveFeedback) {
          this.showAllFive = true

          setTimeout(() => {
            window.location.href = this.link
          }, 1000)
        }
      }

      if (response.error) {
        toast.error(response.error)
      }
      this.$store.commit('setLoaderVisible', false)
    },
    updateFeedbackData(updatedFeedbackData) {
      this.feedbackData = updatedFeedbackData
    },
  },
}
</script>
