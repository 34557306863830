<template>
  <div>
    <h2 class="section__title">{{ text }}</h2>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { convertTime12to24, formatDate } from '@/helpers'

export default {
  name: 'order-rescheduled',
  computed: {
    ...mapGetters(['orderData']),
    text() {
      let text = 'Delivery rescheduled for '
      text += formatDate(this.orderData.deliver.date)
      if (this.orderData.deliver?.from && this.orderData.deliver?.to) {
        text += ` ${convertTime12to24(this.orderData.deliver.from)} - ${convertTime12to24(this.orderData.deliver.to)}`
      }

      return text
    },
  },
}
</script>
