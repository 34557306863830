<template>
  <div>
    <h2 class="section__title">{{ text }}</h2>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatDate } from '@/helpers'

export default {
  name: 'driver-out',
  computed: {
    ...mapGetters(['orderData']),
    text() {
      return 'Confirm your Delivery'
    },
  },
}
</script>
