<template>
  <h2 class="section__title section__title--error">{{ text }}</h2>
</template>

<script>
export default {
  name: 'rejected-text',
  data() {
    return {
      text: 'Order has been canceled by pharmacy',
    }
  },
}
</script>
