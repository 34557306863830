<template>
  <div class="wrapper-section">
    <section class="section delivered-section">
      <div class="section__content">
        <div class="client_info__container client_info__container--copay">
          <p class="payment__delivered">
            <span>Delivered</span>
            <i class="icon payment__delivered__icon">
              <svg viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.625 5L6.75 14l-3.375-3.375" stroke="#fff" stroke-width="2.875" stroke-linecap="round" stroke-linejoin="round"/></svg>
            </i>
          </p>
          <p class="payment__id">#{{ orderData.uid }}</p>
        </div>

        <text-by-status></text-by-status>

        <div class="delivered-pharmacy">
          <delivery-from v-if="orderData.client" :long="true"></delivery-from>
          <div
            v-if="!!orderData?.client?.avatar && orderData.client?.avatar !== '/assets/common/img/temp/avatars/1.jpg'"
            class="pharmacy-logo"
          >
            <div class="bg" :style="'background-image: url(\'' + orderData?.client?.avatar + '\');'"></div>
          </div>
        </div>

        <div class="agent__wrapper">
          <staff-block
            v-if="orderData.driver"
            role-name="Driver"
            :name="orderData.driver.name"
            :rate="orderData.driver.rate"
            :avatar="orderData.driver.avatar"
          ></staff-block>
          <staff-block
            v-if="orderData.dispatcher"
            role-name="Dispatcher"
            :name="orderData.dispatcher.name"
            :rate="orderData.dispatcher.rate"
            :avatar="orderData.dispatcher.avatar"
          ></staff-block>
        </div>

        <driver-comment></driver-comment>

        <photo-slider
          v-if="orderData.note_pictures"
          :disabled="false"
          :photos="orderData.note_pictures"
        ></photo-slider>

        <delivery-to :long="true"></delivery-to>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DeliveryFrom from '@/components/_common/delivery-from.vue'
import StaffBlock from '@/components/_common/staff-block.vue'
import PhotoSlider from '@/components/_common/photo-slider.vue'
import DeliveryTo from '@/components/_common/delivery-to.vue'
import DriverComment from '@/components/_common/driver-comment.vue'
import TextByStatus from './TextByStatus/index.vue'

export default {
  name: 'delivered-info',
  components: {
    DriverComment,
    TextByStatus,
    DeliveryTo,
    PhotoSlider,
    StaffBlock,
    DeliveryFrom,
  },
  computed: {
    ...mapGetters(['orderData']),
  },
}
</script>

<style lang="scss" scoped>
.delivered-pharmacy {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pharmacy-logo {
    .bg {
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
    }
  }
}
</style>
