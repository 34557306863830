<template>
  <template v-if="callFeedback">
    <p>{{ callFeedback }}</p>
    <div class="content">
      <call-feedback-view></call-feedback-view>
      <loader-global></loader-global>
    </div>
  </template>
  <template v-else>
    <div v-if="orderFound" class="content" :class="orderData.client?.customize?.tracking_style_theme">
      <app-header :hide-label="!!signatureLink"></app-header>
      <router-view></router-view>
      <footer-btn></footer-btn>
      <loader-global></loader-global>
    </div>
    <div v-if="error">
      {{ error.message }} (Error status: {{ error.status }})
    </div>
  </template>
</template>
<script setup>
import { RouterView, useRoute } from 'vue-router'
import { onMounted, ref, nextTick } from 'vue'
import { useStore } from 'vuex'
import CryptoJS from 'crypto-js'

import { loadScript } from 'vue-plugin-load-script'

import { getOrderInfo, orderUpdated } from '@/services/orderService'
import FooterBtn from '@/components/FooterBtn/index.vue'
import AppHeader from '@/components/AppHeader/index.vue'
import { dashUrl } from '@/config'
import setDeliveringState from '@/utils/setDeliveringState'
import LoaderGlobal from '@/components/_common/loader-global.vue'
import { getRouteInfo } from '@/services/routeService'
import CallFeedbackView from '@/components/CallFeedbackView/index.vue'
import { getCall } from '@/services/callService'
import NotationHelper from './utils/NotationHelper'

const orderFound = ref(false)
const orderData = ref(null)
const error = ref(null)
const callFeedback = ref(null)
const signatureLink = ref(null)
const store = useStore()
const route = useRoute()

const handleError = err => {
  if (err.response) {
    error.value = {
      status: err.response.status,
      message: err.response.data.error || 'Server error',
    }
  } else {
    error.value = {
      status: null,
      message: 'Can\'t make request',
    }
  }
}

const googleLoader = async () => {
  if (window.google && window.google.map && window.google.map.places) {
    //
  } else {
    // TODO переключить на API
    await fetch(`${dashUrl}/ajax/PullCommon.php?action=getGoogleKey`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(result => result.json())
      .then(({ apikey }) => loadScript(`https://maps.googleapis.com/maps/api/js?key=${apikey}&libraries=places&language=en`))
  }
}

const formatOrderKey = orderUid => {
  let k = CryptoJS.MD5(orderUid)
    .toString()
  const lastChar = parseInt(orderUid.slice(-1), 10)
  const secondLastChar = parseInt(orderUid.slice(-2, -1), 10)
  let last = Math.floor((lastChar + secondLastChar) / 2)
  last = Math.min(last, 5)
  k = k.slice(-last)
  return k
}

const loadTrackingApp = async urlParams => {
  await googleLoader()

  let orderId = urlParams.get('o')
  let timestamp = urlParams.get('t')

  if ((!orderId || !timestamp) && urlParams.toString()) {
    for (const [key, value] of urlParams.entries()) {
      if (!orderId) {
        orderId = NotationHelper.strToDec(key)
      }
      if (!timestamp) {
        timestamp = NotationHelper.strToDec(value)
        break
      }
    }
  }

  if (!orderId && !timestamp) {
    error.value = {
      status: 404,
      message: 'No Order Id',
    }
    return
  }

  store.commit('setUrlParams', urlParams.toString())
  try {
    const { order, driver_note } = await getOrderInfo(orderId)
    store.commit('setOrderData', order)
    store.commit('setDriverNote', driver_note)
    store.commit('setPhones', order?.facility?.uid)

    let route = null
    if (order.routeid && order.status !== 'Delivered') {
      route = await getRouteInfo(order.uid)
      store.commit('setRouteData', route)
    }

    const deliveringState = await setDeliveringState(order, route)
    store.commit('setDeliveringState', deliveringState)

    orderFound.value = true
    orderData.value = order

    await nextTick(() => {
      const pageSections = document.querySelectorAll('.section')
      if (pageSections.length > 0) {
        const lastSection = pageSections[pageSections.length - 1]
        lastSection.style.marginBottom = '0'
      }
    })
  } catch (err) {
    handleError(err)
  }
}

const loadSignatureLinkData = async urlParams => {
  const orderId = urlParams.get('o')
  if (!orderId) {
    error.value = {
      status: 404,
      message: 'No Order Id',
    }
    return
  }

  const h = urlParams.get('h')
  const realHash = formatOrderKey(orderId)

  if (h !== realHash) {
    if (!orderId) {
      error.value = {
        status: 404,
        message: 'Wrong link',
      }
    }
  }
}

let intervalId
const startOrderUpdateCheck = () => {
  intervalId = setInterval(async () => {
    try {
      const { changed } = await orderUpdated(store.state.orderData.uid, store.state.orderData)
      if (changed) {
        window.location.reload()
      }
    } catch (err) {
      console.error('Error with orderUpdated:', err)
      clearInterval(intervalId)
    }
  }, 10000)
}

onMounted(async () => {
  const urlParams = new URLSearchParams(window.location.search)

  const cValue = urlParams.get('c')

  if (cValue !== null && parseInt(cValue, 10) > 0) {
    const orderId = urlParams.get('o')
    const { call } = await getCall(orderId)
    store.commit('setCallData', call)

    callFeedback.value = true
  } else {
    await loadTrackingApp(urlParams)
    await startOrderUpdateCheck()
  }

  if (route.path === '/sg') {
    signatureLink.value = true
  }
})
</script>
