export const stripePublicKey = process.env.VUE_APP_STRIPE_CLIENT_PUBLIC_KEY
export const apiUrl = process.env.VUE_APP_API_TRACK_URL
export const commUrl = process.env.VUE_APP_API_COMMON_URL
export const dashUrl = process.env.VUE_APP_API_DASHBOARD_URL

export const phones = {
  2: { // NY
    formatted: '+1 (929) 744-1114',
    trimmed: '+19297441114',
  },
  3: { // LA
    formatted: '+1 (747) 261-1444',
    trimmed: '+17472611444',
  },
}
