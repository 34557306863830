<template>
  <ul class="feedback__tag_list">
    <li v-for="role in filteredRoles" :key="role">
      <button
        v-for="item in getFeedback(role)"
        :key="item"
        class="feedback__tag_list__item"
        :class="{
          'feedback__tag_list__item--positive': isPositive(role),
          'feedback__tag_list__item--negative': !isPositive(role),
          'feedback__tag_list__item--selected': isSelected(item)
        }"
        style="margin-bottom: 7px"
        @click="addComment(item)"
      >
        {{ item }}
      </button>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'feedback-suggestions',
  props: {
    feedbackSent: {
      type: Boolean,
      required: true,
    },
    feedbackData: {
      type: Object,
      required: true,
    },
  },
  emits: ['update-feedback'],
  data() {
    return {
      suggestions: {
        driver: {
          positive: [
            'Went Above and Beyond',
            'Professional Appearance',
            'Courteous and Respectful',
            'On time delivery',
          ],
          negative: [
            'Unfriendly Driver',
            'Did Not Follow Instructions',
            'Late Delivery',
            'Unprofessional Appearance',
          ],
        },
        dispatcher: {
          positive: [
            'Great Communication',
            'Patient and Understanding',
            'Efficient Coordination',
            'Quick Response Time',
          ],
          negative: [
            'Impatient or Rude',
            'Poor Coordination',
            'Failed to Address Concerns',
            'Slow Response Time',
          ],
        },
        pharmacy: {
          positive: [
            'Friendly Staff',
            'Secure Packaging',
            'Fast Shipping/Refills',
            'Concise Medication Instructions',
          ],
          negative: [
            'Difficult to open',
            'Incorrect Prescription',
            'Unfriendly Staff',
            'Wrong medication',
          ],
        },
      },
    }
  },
  computed: {
    roles() {
      return ['driver', 'dispatcher', 'pharmacy']
    },
    filteredRoles() {
      return this.roles.filter(role => this.feedbackData[`rate_${role}`] !== 0)
    },
  },
  methods: {
    isPositive(role) {
      return this.feedbackData[`rate_${role}`] >= 4
    },
    getFeedback(role) {
      const type = this.isPositive(role) ? 'positive' : 'negative'
      return this.suggestions[role][type]
    },
    addComment(comment) {
      const trimmedComment = comment.trim()
      let newComment = this.feedbackData.comment || ''
      if (trimmedComment && !newComment.includes(trimmedComment)) {
        newComment = newComment ? `${newComment} ${trimmedComment}.` : `${trimmedComment}.`
      }

      this.$emit('update-feedback', { ...this.feedbackData, comment: newComment })
    },
    isSelected(comment) {
      return this.feedbackData.comment && this.feedbackData.comment.includes(comment.trim())
    },
  },
}
</script>
