import axios from '@/axios'

export async function getOrderInfo(orderId) {
  const response = await axios.get(`order/${orderId}/info`)
  return response.data
}

export async function confirmDelivery(orderId, request) {
  const response = await axios.post(`order/${orderId}/confirm-delivery`, request)
  return response.data
}

export async function uploadSign(orderId, request) {
  const response = await axios.post(`order/${orderId}/upload-sign`, request)
  return response.data
}

export async function updateSpecialInstruction(orderId, request) {
  const response = await axios.post(`order/${orderId}/update-instructions`, request)
  return response.data
}

export async function orderUpdated(orderId, orderData) {
  const request = {
    status: orderData.status,
    sub_status: orderData.sub_status,
    activity: orderData.activity,
    confirmed: orderData.confirmed,
  }
  const response = await axios.post(`order/${orderId}/order-updated`, request)
  return response.data
}
