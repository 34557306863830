<template>
  <div class="tips-amount">
    <div class="tips-amount-wrapper">
      <div
        v-for="(suggestion, key) in tipsSuggestions"
        :key="key"
        class="tips-amount-checkbox"
        :class="suggestion.active ? 'active' : ''"
        :style="`width: ${100 / Object.keys(tipsSuggestions).length}%`"
        @click="setActive(key)"
      >
        <template v-if="suggestion.amount !== 0">
          <input
            :id="`tips-amount-${suggestion.amount}`"
            v-model="tips.amountString"
            type="radio"
            :value="suggestion.amount"
            @change="setTips()"
          >
          <label
            :for="`tips-amount-${suggestion.amount}`"
          >
            {{ suggestion.amount }}$
          </label>
        </template>
        <template v-else>
          <input
            v-if="expressElements"
            v-show="showOtherInput"
            ref="tipsInput"
            v-model="tips.amountString"
            v-mask="'$##'"
            pattern="[0-9]*"
            inputmode="numeric"
            class="tips-input"
            @input="setTips()"
          >
          <label
            v-show="!showOtherInput"
          >
            Other
          </label>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'

import { mapGetters } from 'vuex'

export default {
  name: 'tips-amount',
  directives: { mask },
  props: {
    setTipsAmount: {
      type: Function,
      required: true,
    },
    expressElements: {
      type: Object,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      showOtherInput: false,
      errorSumModalShow: false,
      tipsSuggestions: {
        0: {
          active: true,
          amount: 2,
        },
        1: {
          active: false,
          amount: 3,
        },
        2: {
          active: false,
          amount: 5,
        },
        3: {
          active: false,
          amount: 0,
        },
      },
    }
  },
  computed: {
    ...mapGetters(['tips']),
  },
  methods: {
    async setTips() {
      await this.$store.commit('setTipsAmount', this.tips.amountString)
      this.setTipsAmount()
    },
    setActive(selectedIndex) {
      Object.keys(this.tipsSuggestions)
        .forEach(key => {
          this.tipsSuggestions[key].active = (key === selectedIndex)
        })
      this.showOtherInput = Number(selectedIndex) === 3

      if (this.showOtherInput) {
        this.$nextTick(() => {
          this.$refs.tipsInput[0].focus()
        })
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
