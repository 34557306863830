<template>
  <back-to-pharmacy v-if="backToPharmacyCondition"></back-to-pharmacy>
  <confirmed-back-to-pharmacy v-else-if="confirmedBackToPharmacy"></confirmed-back-to-pharmacy>
  <on-its-way-to-pharmacy v-else-if="onWayToPharmacyCondition"></on-its-way-to-pharmacy>
  <pending-back-to-pharmacy v-else-if="pendingBackCondition"></pending-back-to-pharmacy>
  <rejected-text v-else-if="rejectedCondition"></rejected-text>
  <default-text v-else></default-text>
</template>

<script>
import { mapGetters } from 'vuex'
import OnItsWayToPharmacy from '@/components/MainPage/Rejected/BackToPharmacy/TextByStatus/OnItsWayToPharmacy.vue'
import ConfirmedBackToPharmacy
  from '@/components/MainPage/Rejected/BackToPharmacy/TextByStatus/ConfirmedBackToPharmacy.vue'
import PendingBackToPharmacy
  from '@/components/MainPage/Rejected/BackToPharmacy/TextByStatus/PendingBackToPharmacy.vue'
import DefaultText from '@/components/MainPage/Rejected/BackToPharmacy/TextByStatus/DefaultText.vue'
import RejectedText from '@/components/MainPage/Rejected/BackToPharmacy/TextByStatus/Rejected.vue'
import BackToPharmacy from './BackToPharmacy.vue'

export default {
  name: 'text-by-status',
  components: { RejectedText, DefaultText, PendingBackToPharmacy, ConfirmedBackToPharmacy, OnItsWayToPharmacy, BackToPharmacy },
  computed: {
    ...mapGetters(['orderData']),
    backToPharmacyCondition() {
      return this.orderData.status_slug === 'back-to-pharmacy'
    },
    confirmedBackToPharmacy() {
      return this.orderData.sub_status_slug === 'confirmed-back-to-pharmacy'
    },
    onWayToPharmacyCondition() {
      return this.orderData.sub_status_slug === 'on-its-way-to-the-pharmacy'
    },
    pendingBackCondition() {
      if (this.orderData.status_slug === 'pending-back-to-pharmacy') {
        if (this.orderData.sub_status_slug === 'open') {
          return true
        }
      }

      if (this.orderData.status_slug === 'delivery-attempted') {
        if (this.orderData.sub_status_slug === 'send-to-the-route') {
          return true
        }
      }

      return this.orderData.sub_status_slug === 'pending-back-to-pharmacy'
    },
    rejectedCondition() {
      return this.orderData.status_slug === 'rejected'
    },
  },
}
</script>
