<template>
  <div class="wrapper-section pb-10">
    <div class="section">
      <div class="section__content order">
        <text-by-status></text-by-status>
        <copay-block></copay-block>
        <deliver-progress-line></deliver-progress-line>

        <div class="client_info__container">
          <promised-eta></promised-eta>
          <delivery-from v-if="orderData.client"></delivery-from>
          <delivery-to :long="true"></delivery-to>
        </div>

        <reschedule-btn></reschedule-btn>

        <approve-type></approve-type>
        <confirmation-form></confirmation-form>
      </div>
    </div>
  </div>
  <div v-if="allowTips" class="wrapper-section" style="margin-top: 0; padding-bottom: 5px">
    <div class="tips-section-border" style="margin-bottom: 0;">
      <tips-component></tips-component>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import TextByStatus from '@/components/MainPage/Delivering/NotConfirmed/components/TextByStatus/index.vue'
import CopayBlock from '@/components/_common/copay-block.vue'
import DeliverProgressLine from '@/components/MainPage/Delivering/NotConfirmed/components/delivery-progress-line.vue'
import DeliveryFrom from '@/components/_common/delivery-from.vue'
import DeliveryTo from '@/components/_common/delivery-to.vue'
import ApproveType from '@/components/_common/ApproveType/index.vue'
import ConfirmationForm from '@/components/MainPage/Delivering/NotConfirmed/components/confirmation-form.vue'
import TipsComponent from '@/components/_common/TipsComponents/tips-component.vue'
import PromisedEta from '@/components/_common/promised-eta.vue'
import RescheduleBtn from '@/components/_common/reschedule-btn.vue'

export default {
  name: 'not-confirmed-view',
  components: {
    RescheduleBtn,
    PromisedEta,
    TipsComponent,
    ConfirmationForm,
    ApproveType,
    DeliveryTo,
    DeliveryFrom,
    DeliverProgressLine,
    CopayBlock,
    TextByStatus,
  },
  computed: {
    ...mapGetters(['orderData', 'allowTips', 'deliveringState']),
  },
}
</script>

<style scoped>

</style>
