<template>
  <div :class="['client_info', long ? 'client_info--long' : '']">
    <i class="icon client_info__icon">
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 16c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zM8 1.333A6.674 6.674 0 001.333 8 6.674 6.674 0 008 14.667 6.674 6.674 0 0014.667 8 6.674 6.674 0 008 1.333zM11.333 8a.666.666 0 00-.666-.667h-2V4a.666.666 0 10-1.334 0v4c0 .369.299.667.667.667h2.667A.666.666 0 0011.333 8z" fill="#1EBEC8"/></svg>
    </i>
    <h4 class="client_info__title">Promised ETA:</h4>
    <p class="client_info__value" style="line-height: 23px" v-html="promisedEta"></p>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { formatDate } from '@/helpers'

export default {
  name: 'promised-eta',
  props: {
    long: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['orderData', 'dateToDeliver', 'timeToDeliver']),
    promisedEta() {
      if (!this.dateToDeliver) {
        return 'Delivery date unavailable'
      }
      return this.timeToDeliver ? `${this.timeToDeliver}<br>${this.dateToDeliver}` : this.dateToDeliver
    },
  },
}
</script>

<style scoped lang="scss">

</style>
