<template>
  <div class="qr-block" @click="qrModalShow()">
    <div class="qr-code">
      <qrcode-vue :value="orderData.qr_approve" :size="70"></qrcode-vue>
      <p class="code">{{ orderData.qr_approve }}</p>
    </div>
    <div class="info-text">Show the QR code to the driver</div>
  </div>
  <qr-popup
    :is-visible="popupShow"
    :qr-number="orderData.qr_approve"
    @close="popupShow = false"
  ></qr-popup>
</template>

<script>
import { mapGetters } from 'vuex'
import QrcodeVue from 'qrcode.vue'
import QrPopup from '@/components/MainPage/Delivering/Confirmed/components/qr-popup.vue'

export default {
  name: 'qr-block-small',
  components: {
    QrPopup,
    QrcodeVue,
  },
  data() {
    return {
      popupShow: false,
    }
  },
  computed: {
    ...mapGetters(['orderData']),
  },
  methods: {
    qrModalShow() {
      this.popupShow = true
    },
  },
}
</script>

<style scoped lang="scss">

</style>
