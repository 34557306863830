<template>
  <div class="section__content driver-tips">
    <h2 class="section__title">Thank you for<br><span>supporting drivers!</span></h2>
    <p v-if="orderData.driver" class="section__desc">100% of your tip goes directly to <strong>{{ orderData.driver?.name }}</strong></p>

    <div class="client_info__container form">
      <tips-amount :set-tips-amount="setTipsAmount" :express-elements="expressElements"></tips-amount>
    </div>
  </div>
  <form class="client_info__payment_container">
    <div ref="cardElement" class="form-control client_info__payment_container__card_element"></div>
    <div class="payment-buttons-block">
      <div v-if="needConfirm" class="confirm-click" @click="confirmTips()"></div>
      <div class="payment-buttons">
        <button
          ref="paymentButton"
          type="button"
          class="pay-card-btn"
          :disabled="paymentButtonDisabled"
          @click="payTip()"
        >
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.103 4.468h13.75c.16 0 .321.01.48.03a2.834 2.834 0 00-3.33-2.387L3.695 4.041h-.012c-.71.136-1.341.538-1.765 1.123a3.763 3.763 0 012.185-.696zM17.854 5.5H4.104a2.753 2.753 0 00-2.75 2.75v8.25a2.753 2.753 0 002.75 2.75h13.75a2.753 2.753 0 002.75-2.75V8.25a2.754 2.754 0 00-2.75-2.75zm-2.042 8.25a1.375 1.375 0 110-2.75 1.375 1.375 0 010 2.75z" fill="#fff"/><path d="M1.375 11.15V6.875c0-.932.516-2.493 2.305-2.831C5.2 3.76 6.704 3.76 6.704 3.76s.988.687.171.687c-.816 0-.795 1.053 0 1.053s0 1.01 0 1.01l-3.201 3.63-2.299 1.01z" fill="#fff"/></svg> Pay Card
        </button>
        <div ref="expressCheckout" class="buttons_stripe_container"></div>
      </div>
    </div>
    <p ref="paymentResult" class="text-muted payment_result"></p>
    <t-modal :is-visible="showConfirmModal" class="modal-confirm" @close="showConfirmModal = !showConfirmModal">
      <template #body>
        <p class="title">Please confirm:</p>
        <p class="text">Are you sure you want to send a tip of <strong>${{ tips.amount }}</strong>?</p>
      </template>
      <template #footer>
        <div class="confirm-modal-buttons">
          <div class="btn btn-confirm" @click="needConfirm = false; showConfirmModal = false">Confirm</div>
          <div class="btn btn-cancel" @click="showConfirmModal = false">Cancel</div>
        </div>
      </template>
    </t-modal>
    <t-modal :is-visible="errorSumModalShow" @close="errorSumModalShow = !errorSumModalShow">
      <template #error>
        The tip amount must be between $2 and $40.
      </template>
    </t-modal>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { paymentIntent } from '@/services/paymentService'
import { dashUrl, stripePublicKey } from '@/config'
import TModal from '@/components/UI/t-modal.vue'
import TipsAmount from './tips-amount.vue'

export default {
  components: { TModal, TipsAmount },
  data() {
    return {
      needConfirm: true,
      showConfirmModal: false,
      errorSumModalShow: false,
      fee: {},
      fees: {
        USD: {
          Percent: 2.9,
          Fixed: 0.30,
        },
        GBP: {
          Percent: 2.4,
          Fixed: 0.20,
        },
        EUR: {
          Percent: 2.4,
          Fixed: 0.24,
        },
        CAD: {
          Percent: 2.9,
          Fixed: 0.30,
        },
        AUD: {
          Percent: 2.9,
          Fixed: 0.30,
        },
        NOK: {
          Percent: 2.9,
          Fixed: 2,
        },
        DKK: {
          Percent: 2.9,
          Fixed: 1.8,
        },
        SEK: {
          Percent: 2.9,
          Fixed: 1.8,
        },
        JPY: {
          Percent: 3.6,
          Fixed: 0,
        },
        MXN: {
          Percent: 3.6,
          Fixed: 3,
        },
      },
      stripe: null,
      elements: null,
      expressElements: null,
      card: null,
      intent: null,
      clientSecret: null,
      badgeActiveClasses: {
        1: true,
        2: false,
        3: false,
      },
      paymentButtonDisabled: false,
      customAmountPromptVisible: true,
      customAmountInputVisible: false,
      expressCheckoutElement: null,
    }
  },
  computed: {
    ...mapGetters(['tips', 'orderData']),
  },
  watch: {
    expressCheckoutElement(current, previous) {
      if (previous === null) {
        current.mount(this.$refs.expressCheckout)
      }
    },
    // eslint-disable-next-line func-names
    'tips.amount': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.needConfirm = true
      }
    },
  },
  async mounted() {
    let stripePk = this.orderData.facility.customize.strip_public_key

    if (!stripePk) {
      stripePk = stripePublicKey
    }

    this.stripe = await window.Stripe(stripePk, {
      locale: 'en',
      apiVersion: '2020-08-27',
    })

    this.elements = this.stripe.elements()
    this.card = this.elements.create('card')
    this.card.mount(this.$refs.cardElement)

    await this.initStripe()

    // payment result
    this.card.addEventListener('change', event => {
      if (event.error) {
        this.$refs.paymentResult.textContent = event.error.message
      } else {
        this.$refs.paymentResult.textContent = ''
      }
    })

    await this.setTipsAmount()
  },
  methods: {
    async initStripe() {
      let tipsAmount = Number(String(this.tips.amount)
        .replace(/\D/g, ''))
      if (!tipsAmount || tipsAmount === 0) {
        tipsAmount = 1
      }

      this.expressElements = this.stripe.elements({
        mode: 'payment',
        amount: tipsAmount * 100,
        currency: 'usd',
        appearance: {
          variables: {
            borderRadius: '50px',
          },
        },
      })

      await this.getIntent()

      const expressCheckoutOptions = {
        wallets: {
          applePay: 'always',
          googlePay: 'always',
        },
        buttonType: {
          googlePay: 'pay',
        },
        buttonHeight: 50,
        paymentMethodOrder: ['applePay', 'googlePay', 'link'],
        layout: {
          maxColumns: 1,
        },
      }

      this.expressCheckoutElement = this.expressElements.create(
        'expressCheckout',
        expressCheckoutOptions,
      )
      this.makePaymentRequest()
    },
    async setTipsAmount() {
      let tipsAmount = Number(String(this.tips.amount)
        .replace(/\D/g, ''))
      if (!tipsAmount || tipsAmount === 0) {
        tipsAmount = 2
      }
      if (tipsAmount && tipsAmount !== 0) {
        this.fee = this.calcFee(tipsAmount, 'USD')

        this.expressElements.update({
          amount: tipsAmount * 100,
        })

        await this.getIntent()
      }
    },
    async getIntent() {
      let tipsAmount = Number(String(this.tips.amount)
        .replace(/\D/g, ''))
      if (!tipsAmount || tipsAmount === 0) {
        tipsAmount = 1
      }
      const { payload } = await paymentIntent({
        amount: tipsAmount * 100,
        order_id: this.orderData.uid,
      })
      this.intent = payload
      this.clientSecret = payload.client_secret
    },
    makePaymentRequest() {
      this.expressCheckoutElement.on('confirm', ev => {
        this.stripe.confirmPayment({
          elements: this.expressElements,
          clientSecret: this.clientSecret,
          confirmParams: {
            return_url: window.location.href,
          },
        })
      })
    },
    calcFee(amountArg, currency) {
      const _fee = this.fees[currency]
      const amount = parseFloat(amountArg)
      const total = (amount + parseFloat(_fee.Fixed) * 1.1) / (1 - (parseFloat(_fee.Percent) * 1.1) / 100)
      const fee = total - amount

      return {
        amount,
        fee: fee.toFixed(2),
        total: total.toFixed(2),
      }
    },
    payTip() {
      const self = this
      this.paymentButtonDisabled = true

      this.$refs.paymentResult.textContent = ''
      this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
      })
        .then(this.handlePaymentMethodResult)
        .finally(() => {
          self.paymentButtonDisabled = false
        })
    },
    handleServerResponse(responseJson) {
      this.paymentButtonDisabled = true

      if (responseJson.error) {
        // An error happened when charging the card, show it in the payment form
        this.$refs.paymentResult.textContent = responseJson.error

        this.$swal.fire({
          title: 'Error',
          type: 'error',
          text: `Transaction error! ${responseJson.error}`,
          confirmButtonText: 'Okay',
          confirmButtonClass: 'btn-danger',
        })
      } else {
        // Show a success message
        this.$refs.paymentResult.textContent = 'Success!'
        window.location.reload()
      }
    },
    handlePaymentMethodResult(result) {
      if (result.error) {
        this.paymentButtonDisabled = true
        // An error happened when collecting card details, show it in the payment form
        this.$refs.paymentResult.textContent = result.error.message
      } else {
        // Otherwise send paymentMethod.id to your server (see Step 3)
        fetch(`${dashUrl}/payment/tip`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          // body: JSON.stringify({ payment_method_id: result.paymentMethod.id, amount: fee.total, order_id: "<?= $oid ?>"}) // TEMP NOT UPPER FEE
          body: JSON.stringify({
            payment_method_id: result.paymentMethod.id,
            amount: this.fee.amount,
            order_id: this.orderData.uid,
          }),
        })
          .then(result => result.json())
          .then(this.handleServerResponse)
      }
    },
    confirmTips() {
      if (this.tips.amount < 2) {
        this.errorSumModalShow = true
        this.$store.commit('setTipsAmount', 2)
        this.$store.commit('setTipsAmountString', 2)
      } else if (this.tips.amount > 40) {
        this.errorSumModalShow = true
        this.$store.commit('setTipsAmountString', 40)
      } else {
        this.showConfirmModal = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-buttons-block {
  position: relative;

  .confirm-click {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .payment-buttons {
    position: relative;
    z-index: 1;
  }
}

.modal-confirm {
  .title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
  }

  .text {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
  }

  .confirm-modal-buttons {
    display: flex;

    .btn {
      cursor: pointer;
      color: #ffffff;
      font-weight: 700;
      font-size: 18px;
      padding: 10px 5px;

      &-confirm {
        background-color: #44D453;
        margin-right: 5px;
      }

      &-cancel {
        background-color: #E64646;
        margin-left: 5px;
      }
    }
  }
}
</style>
