import { createApp } from 'vue'

import './assets/scss/main.scss'

import store from '@/store'

import VueSignaturePad from 'vue-signature-pad'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import App from './App.vue'
import router from './router'

import components from './components/UI'

const app = createApp(App)

components.forEach(component => {
  app.component(component.name, component)
})

app.use(Toast, {})
app.use(VueSignaturePad)
app.use(VueSweetalert2)

app.use(router)
app.use(store)
app.mount('#app')
