<template>
  <div class="wrapper-section pb-10">
    <div class="section">
      <div class="section__content order">
        <text-by-status></text-by-status>
        <copay-block></copay-block>

        <deliver-progress-line :force-status="1" :attempts="orderData.attempts"></deliver-progress-line>

        <div v-if="lastAttempted" class="attempted-on">
          <p>Attempted on {{ lastAttempted }}</p>
        </div>

        <div class="client_info__container">
          <delivery-from
            v-if="orderData.client"
            :long="true"
          ></delivery-from>
          <delivery-to
            :long="true"
            :new="true"
          ></delivery-to>
        </div>

        <reschedule-btn></reschedule-btn>

        <approve-type></approve-type>

        <confirmation-form></confirmation-form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import TextByStatus from '@/components/MainPage/Delivering/NotConfirmed/components/TextByStatus/index.vue'
import CopayBlock from '@/components/_common/copay-block.vue'
import DeliverProgressLine from '@/components/MainPage/Delivering/NotConfirmed/components/delivery-progress-line.vue'
import DeliveryFrom from '@/components/_common/delivery-from.vue'
import DeliveryTo from '@/components/_common/delivery-to.vue'
import ApproveType from '@/components/_common/ApproveType/index.vue'
import ConfirmationForm from '@/components/MainPage/Delivering/NotConfirmed/components/confirmation-form.vue'
import RescheduleBtn from '@/components/_common/reschedule-btn.vue'
import moment from 'moment'

export default {
  name: 'rescheduled-component',
  components: {
    RescheduleBtn,
    ConfirmationForm,
    ApproveType,
    DeliveryTo,
    DeliveryFrom,
    DeliverProgressLine,
    CopayBlock,
    TextByStatus },
  computed: {
    ...mapGetters(['orderData']),
    lastAttempted() {
      if (this.orderData.status_slug === 'delivery-attempted' || this.orderData.status_slug === 'returned') {
        if (this.orderData.status_at_dt) {
          return moment(this.orderData.status_at_dt).format('MMMM Do hh:mm a')
        }
      }

      return null
    },
  },
}
</script>

<style scoped>

</style>
