<template>
  <div class="wrapper-section pb-10">
    <div class="section mb-0">
      <div class="section__content order">
        <h2 class="section__title">
          <a :href="'tel:' + phonesNumbers.trimmed">
            Please contact us at
            <span style="text-decoration: underline">{{ phonesNumbers.formatted }}</span> to schedule a
            delivery
          </a>
        </h2>
        <copay-block></copay-block>
        <deliver-progress-line :force-status="2"></deliver-progress-line>

        <div class="client_info__container">
          <promised-eta></promised-eta>
          <delivery-from v-if="orderData.client" :long="true"></delivery-from>
          <delivery-to :long="true"></delivery-to>
        </div>

        <approve-type></approve-type>
        <div v-if="orderData.instructions.pharmacy" style="width: 100%; margin-top: 10px;">
          <h5 class="info_title form__input__title">Pharmacy Special Instruction:</h5>
          <p style="margin-bottom: 10px; font-size: 14px; color: #757575">{{ orderData.instructions.pharmacy }}</p>
        </div>
        <div v-if="orderData.instructions.recipient" style="width: 100%; margin-top: 10px;">
          <h5 class="info_title form__input__title">Pharmacy Special Instruction:</h5>
          <p style="margin-bottom: 10px; font-size: 14px; color: #757575">{{ orderData.instructions.recipient }}</p>
        </div>
        <a :href="'tel:' + phonesNumbers.trimmed" class="btn form__btn success">Call {{ phonesNumbers.formatted }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import DeliveryFrom from '@/components/_common/delivery-from.vue'
import ApproveType from '@/components/_common/ApproveType/index.vue'
import DeliverProgressLine from '@/components/MainPage/Delivering/NotConfirmed/components/delivery-progress-line.vue'
import PromisedEta from '@/components/_common/promised-eta.vue'
import DeliveryTo from '@/components/_common/delivery-to.vue'
import CopayBlock from '@/components/_common/copay-block.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'investigation-view',
  components: {
    CopayBlock,
    DeliveryTo,
    PromisedEta,
    DeliverProgressLine,
    ApproveType,
    DeliveryFrom,
  },
  computed: {
    ...mapGetters(['orderData', 'urlParams', 'allowTips', 'deliveringState', 'phonesNumbers']),
  },
}
</script>

<style scoped lang="scss">

</style>
