import axios from '@/axios'

export async function storeFeedback(request) {
  const response = await axios.post('feedback/store', request)
  return response.data
}

export async function getFeedback(orderId) {
  const response = await axios.get(`feedback/${orderId}`)
  return response.data
}
