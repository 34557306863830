<template>
  <div class="client_info__container client_info__container--copay">
    <template v-if="copay">
      <p class="payment__copay">Copay: ${{ copay }}</p>
      <button class="tip_btn js--btn-tip" @click="toggleTooltip">
        <i class="icon ">
          <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 2.406c-4.74 0-8.594 3.856-8.594 8.594 0 4.74 3.856 8.594 8.594 8.594 4.74 0 8.594-3.856 8.594-8.594 0-4.74-3.856-8.594-8.594-8.594zm0 3.524a1.117 1.117 0 11.064 2.233A1.117 1.117 0 0111 5.93zm2.063 9.71H9.28a.688.688 0 010-1.375h1.204v-3.78h-.688a.688.688 0 110-1.375h1.375a.688.688 0 01.688.687v4.468h1.203a.687.687 0 110 1.375z" fill="#9f9d9c"/></svg>
        </i>
      </button>
      <p class="tip_help" :class="{ active: isTooltipActive }" @click="toggleTooltip">
        A copay is a specific dollar amount
        that you’re required to pay for covered health care services or prescriptions, as defined by your insurance
        plan.
      </p>
    </template>
    <template v-else>
      <p v-if="dateTimeAttempted" class="date-time-attempted">{{ dateTimeAttempted }}</p>
    </template>
    <p class="payment__id">#{{ orderId }}</p>
  </div>
</template>

<script>
export default {
  name: 'copay-block',
  props: {
    dateTimeAttempted: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isTooltipActive: false,
    }
  },
  computed: {
    copay() {
      return this.$store.getters.orderData.copay
    },
    orderId() {
      return this.$store.getters.orderData.uid
    },
  },
  methods: {
    toggleTooltip() {
      this.isTooltipActive = !this.isTooltipActive
    },
  },
}
</script>
