import axios from '@/axios'

export async function rescheduleOrder(orderId, request) {
  const response = await axios.post(`order/${orderId}/reschedule`, request)
  return response.data
}

export async function changeAddress(orderId, request) {
  const response = await axios.post(`order/${orderId}/change-address`, request)
  return response.data
}

export async function checkDelivery(request) {
  return axios.post('check-delivery', request)
}
