<template>
  <div class="form__input">
    <h5 class="info_title form__input__title" style="line-height: 120%">
      Sign below to allow package drop-off without
      signature:
    </h5>
    <div class="form__input__pseudo_canvas">
      <VueSignaturePad
        ref="signaturePad"
        class="signature-canvas"
        :options="{onBegin, ...options}"
      ></VueSignaturePad>
      <button class="form__input__pseudo_canvas__clear_btn" type="button" @click="clearCanvas">
        <i class="icon form__input__pseudo_canvas__clear_btn__icon">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.443 7.667l4.128-4.025c.877-.856 2.408-.856 3.284 0l4.447 4.337a2.223 2.223 0 010 3.196l-4.125 4.023-7.734-7.531zm5.329 9.877l1.347-1.314-7.734-7.531-3.707 3.616a2.223 2.223 0 000 3.196L6.255 19H20v-1.456h-7.229z" fill="#1EBEC8"/></svg>
        </i>
        <span>Clear</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'signature-component',
  components: {},
  props: {
    changedSignature: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      signaturePad: null,
      options: {
        penColor: '#076ba9',
      },
    }
  },
  mounted() {
    this.signaturePad = this.$refs.signaturePad
  },
  methods: {
    clearCanvas() {
      this.signaturePad.clearSignature()
    },
    onBegin() {
      this.changedSignature()
    },
    // async cropSignature(data) {
    //   const image = new Image()
    //   image.src = data

    //   await new Promise(resolve => {
    //     image.onload = resolve
    //   })

    //   const canvas = document.createElement('canvas')
    //   const ctx = canvas.getContext('2d')
    //   canvas.width = image.width
    //   canvas.height = image.height

    //   ctx.drawImage(image, 0, 0)

    //   const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    //   const signatureData = imageData.data

    //   let minX = canvas.width
    //   let minY = canvas.height
    //   let maxX = 0
    //   let maxY = 0

    //   for (let x = 0; x < canvas.width; x++) {
    //     for (let y = 0; y < canvas.height; y++) {
    //       const alpha = signatureData[(y * canvas.width + x) * 4 + 3]
    //       if (alpha > 0) {
    //         minX = Math.min(minX, x)
    //         maxX = Math.max(maxX, x)
    //         minY = Math.min(minY, y)
    //         maxY = Math.max(maxY, y)
    //       }
    //     }
    //   }

    //   const width = maxX - minX + 1
    //   const height = maxY - minY + 1

    //   const croppedCanvas = document.createElement('canvas')
    //   croppedCanvas.width = width
    //   croppedCanvas.height = height

    //   const croppedCtx = croppedCanvas.getContext('2d')
    //   croppedCtx.drawImage(canvas, minX, minY, width, height, 0, 0, width, height)

    //   return croppedCanvas.toDataURL()
    // },
    async getSignature() {
      const {
        isEmpty,
        data,
      } = this.signaturePad.saveSignature('image/svg+xml')
      if (!isEmpty) {
        return data
        // return this.cropSignature(data)
      }

      return null
    },
    isEmptySignature() {
      const { isEmpty } = this.signaturePad.saveSignature()
      return isEmpty
    },
  },
}
</script>

<style scoped>
.signature-canvas {
  width: 100%;
  height: 100%;
}
</style>
