<template>
  <h2 class="section__title section__title--error">{{ text }}</h2>
</template>

<script>
export default {
  name: 'on-its-way-to-pharmacy',
  data() {
    return {
      text: 'Your order has been canceled and confirmed return back to the pharmacy',
    }
  },
}
</script>
