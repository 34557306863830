<template>
  <div>
    <h2 class="section__title">{{ text }}</h2>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatDate } from '@/helpers'

export default {
  name: 'pickup-occurred',
  computed: {
    ...mapGetters(['orderData', 'dateToDeliver', 'timeToDeliver']),
    text() {
      let text = 'Your order has been picked up from the pharmacy and will be delivered '
      if (this.dateToDeliver) {
        text += this.dateToDeliver
      }

      if (this.timeToDeliver) {
        text += ` ${this.timeToDeliver}`
      }

      return text
    },
  },
}
</script>
