<template>
  <div
    :class="['form__input', { 'form__input--small': small, 'disabled': disabled }]"
  >
    <h5
      v-if="title"
      class="info_title form__input__title"
      :class="{'info_title--small': titleGrey}"
    >
      {{ title }}:
    </h5>
    <div class="form__input__field_wrapper">
      <input
        ref="inputRef"
        :value="modelValue"
        class="form__input__field_text"
        type="text"
        :placeholder="placeholder"
        @input="updateInput"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 't-input',
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    titleGrey: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    },
    focusInput() {
      this.$refs.inputRef.focus()
    },
  },
}
</script>
