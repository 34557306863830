<template>
  <div v-if="isVisible" class="photo-modal" @click="handleBackgroundClick">
    <div class="modal-content" @click="handleModalContentClick">
      <div class="modal-slider-action-container">
        <a class="modal-slider-action-item" @click="zoomHandle">
          <i v-if="swiper" class="icon">
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="zoom" v-bind:svg-inline="''" v-bind:width="'34'" v-bind:style="'width: 34px'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_307_5537)" fill="#fff"><path d="M30.327 27.983l-6.248-6.247a11.543 11.543 0 002.313-6.946c0-6.398-5.204-11.602-11.602-11.602-6.398 0-11.602 5.204-11.602 11.602 0 6.398 5.204 11.602 11.602 11.602 2.505.003 4.942-.809 6.945-2.313l6.247 6.248a1.659 1.659 0 002.345-2.344zM6.502 14.79a8.288 8.288 0 118.288 8.288 8.297 8.297 0 01-8.287-8.288z"/><path d="M22.892 6.645c-4.479-4.479-11.768-4.479-16.247 0-4.479 4.48-4.479 11.768 0 16.247 4.48 4.48 11.768 4.48 16.247 0 4.48-4.479 4.48-11.768 0-16.247zm-7.24 13.123a.883.883 0 11-1.766 0v-4.116H9.77a.884.884 0 010-1.766h4.116V9.77a.884.884 0 011.766 0v4.116h4.116a.884.884 0 010 1.766h-4.116v4.116z"/></g><defs><clipPath id="clip0_307_5537"><rect width="34" height="34" rx="4" fill="#fff"/></clipPath></defs></svg>
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!zoom" v-bind:svg-inline="''" v-bind:width="'34'" v-bind:style="'width: 34px'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_307_5537)" fill="#fff"><path d="M30.327 27.983l-6.248-6.247a11.543 11.543 0 002.313-6.946c0-6.398-5.204-11.602-11.602-11.602-6.398 0-11.602 5.204-11.602 11.602 0 6.398 5.204 11.602 11.602 11.602 2.505.003 4.942-.809 6.945-2.313l6.247 6.248a1.659 1.659 0 002.345-2.344zM6.502 14.79a8.288 8.288 0 118.288 8.288 8.297 8.297 0 01-8.287-8.288z"/><path d="M22.892 6.645c-4.479-4.479-11.768-4.479-16.247 0-4.479 4.48-4.479 11.768 0 16.247 4.48 4.48 11.768 4.48 16.247 0 4.48-4.479 4.48-11.768 0-16.247zm-7.24 13.123a.883.883 0 11-1.766 0v-4.116H9.77a.884.884 0 010-1.766h4.116V9.77a.884.884 0 011.766 0v4.116h4.116a.884.884 0 010 1.766h-4.116v4.116z"/></g><defs><clipPath id="clip0_307_5537"><rect width="34" height="34" rx="4" fill="#fff"/></clipPath></defs></svg>
          </i>
        </a>
        <a class="modal-slider-action-item" @click="close">
          <i class="icon">
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:width="'34'" v-bind:style="'width: 34px'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M24.438 24.438L9.563 9.563M24.438 9.563L9.563 24.437" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </i>
        </a>
      </div>
      <div class="modal-body" style="max-height: 80vh">
        <div v-if="photos.length === 1">
          <div class="swiper-zoom-container">
            <img
              :src="photos[0].path"
              :alt="'driver report img ' + photos[0].id"
              class="main-slider-img"
            >
          </div>
          <div class="main-slider-img-title-container">
            <span class="main-slider-img-title">{{ texts[photos[0].type] }}</span>
          </div>
        </div>
        <swiper
          v-if="!!swiperThumbs"
          ref="mainSwiper"
          :thumbs="{ swiper: swiperThumbs }"
          :zoom="true"
          :initial-slide="initialPhoto"
          @swiper="setSwiper"
        >
          <swiper-slide
            v-for="(photo, index) in photos"
            :key="index"
          >
            <div class="swiper-zoom-container">
              <img
                :src="photo.path"
                :alt="'driver report img ' + photo.id"
                class="main-slider-img"
              >
            </div>
            <div class="main-slider-img-title-container">
              <span class="main-slider-img-title">{{ texts[photo.type] }}</span>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div v-if="photos.length > 1" class="modal-footer">
        <swiper
          class="tumbs-swiper"
          :space-between="0"
          :slides-per-view="5"
          :zoom="true"
          @swiper="setThumbsSwiper"
        >
          <swiper-slide
            v-for="(photo, index) in photos"
            :key="index"
          >
            <img
              :src="photo.path"
              :alt="'driver report img ' + photo.id"
              class="img-tumbs"
            >
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Zoom, Navigation, Thumbs } from 'swiper/modules'
import SwiperCore from 'swiper'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Zoom, Navigation, Thumbs])

export default {
  name: 'photo-modal',
  components: {
    SwiperSlide,
    Swiper,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    photos: {
      type: Array,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    texts: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      swiperThumbs: null,
      swiper: null,
      selectedPhoto: null,
      zoom: false,
    }
  },
  computed: {
    initialPhoto() {
      const photo = this.photos.find(p => p.id === this.id)
      return this.photos.indexOf(photo)
    },
  },
  methods: {
    handleBackgroundClick() {
      this.close()
    },
    handleModalContentClick(event) {
      event.stopPropagation()
    },
    close() {
      this.swiperThumbs = null
      this.swiper = null
      this.$emit('close')
    },
    zoomHandle() {
      if (this.zoom) {
        this.swiper.zoom.out()
        this.zoom = false
      } else {
        this.swiper.zoom.in()
        this.zoom = true
      }
    },
    setSwiper(swiper) {
      this.swiper = swiper
    },
    setThumbsSwiper(swiper) {
      this.swiperThumbs = swiper
    },
  },
}
</script>

<style lang="scss" scoped>
.single-photo {
  width: 100%;
}
</style>
