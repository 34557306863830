<template>
  <div class="agent">
    <div class="agent__preview" style="height: 70px;">
      <template v-if="!avatar || avatar === '/assets/common/img/temp/avatars/1.jpg'">
        <img
          v-if="roleName === 'Dispatcher'"
          class="avatar-icon"
          src="@/assets/img/dispatcher.png"
          alt=""
        >
        <img
          v-if="roleName === 'Driver'"
          class="avatar-icon"
          src="@/assets/img/driver.png"
          alt=""
        >
      </template>
      <div v-else class="agent-img">
        <div class="agent-bg" :style="{'background-image': 'url(' + avatar + ')'}"></div>
      </div>
      <div class="agent__preview__rate">
        <div class="icon agent__preview__rate__icon">
          <svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.082 4.078c-.16-.154-.073-.434.143-.46l3.055-.38a.256.256 0 00.2-.154L4.77.155a.25.25 0 01.461 0l1.289 2.93a.255.255 0 00.2.152l3.055.38c.216.027.302.307.143.461L7.66 6.268a.275.275 0 00-.077.248l.6 3.164c.042.224-.185.397-.374.285L5.124 8.39a.244.244 0 00-.248 0L2.192 9.966c-.19.111-.417-.061-.374-.285l.6-3.164a.275.275 0 00-.078-.247L.082 4.079z" fill="#FFCF23"/></svg>
        </div>
        <p class="agent__preview__rate__value">{{ rating }}</p>
      </div>
    </div>

    <div class="agent__info agent__info--report">
      <p class="agent__info__role">
        {{ roleName }}:
      </p>
      <div class="agent__info__title agent__info__title--report">
        <p>{{ name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'staff-block',
  props: {
    roleName: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    rate: {
      type: String,
      required: true,
    },
    avatar: {
      type: String,
      required: true,
    },
  },
  computed: {
    rating() {
      const rate = Number(this.rate)
      if (rate < 9) {
        return 4.8
      }
      return (this.rate / 2).toFixed(1)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
