<template>
  <div class="wrapper-section">
    <section class="section">
      <div class="section__content">
        <signature-pad></signature-pad>
      </div>
    </section>
  </div>
</template>

<script>
import SignaturePad from '@/components/SignatureLink/signature-pad.vue'
import SignAccepted from '@/components/SignatureLink/sign-accepted.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'signature-link',
  components: {
    SignaturePad,
  },
  computed: {
    ...mapGetters(['orderData']),
  },
}
</script>
