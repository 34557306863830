<template>
  <li class="feedback__mark__item">
    <div class="rating-avatar">
      <template v-if="!photo || photo === '/assets/common/img/temp/avatars/1.jpg'">
        <div
          v-if="type === 'Pharmacy'"
          class="rating-avatar-bg"
          :style="{'background-image': 'url(' + pharmacyIcon + ')'}"
        ></div>
        <div
          v-if="type === 'Dispatcher'"
          class="rating-avatar-bg"
          :style="{'background-image': 'url(' + dispatcherIcon + ')'}"
        ></div>
        <div
          v-if="type === 'Driver'"
          class="rating-avatar-bg"
          :style="{'background-image': 'url(' + driverIcon + ')'}"
        ></div>
      </template>
      <div
        v-else
        class="rating-avatar-bg"
        :style="{'background-image': 'url(' + photo + ')'}"
      ></div>
    </div>
    <p class="feedback__mark__role">{{ type }}</p>
    <div class="feedback__mark__stars_wrapper">
      <input
        v-for="star in maxStars"
        :id="name + 'Star' + star"
        :key="'input-' + star"
        type="radio"
        :name="name"
        :value="star"
        :checked="modelValue === star"
        class="feedback__mark__radio"
        :disabled="disabled"
        @change="updateInput($event)"
      >
      <label
        v-for="star in maxStars"
        :key="'label-' + star"
        :for="name + 'Star' + star"
        class="icon feedback__mark__star"
      >
        <img :src="localRating >= star ? activeStar : disabledStar" :alt="'Star ' + star">
      </label>
    </div>
  </li>
</template>

<script>
import activeStar from '@/assets/img/star.svg'
import disabledStar from '@/assets/img/star_disable.svg'

import driverIcon from '@/assets/img/driver.png'
import dispatcherIcon from '@/assets/img/dispatcher.png'
import pharmacyIcon from '@/assets/img/pharmacy.png'

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    maxStars: {
      type: Number,
      default: 5,
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    photo: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      activeStar,
      disabledStar,
      driverIcon,
      dispatcherIcon,
      pharmacyIcon,
      localRating: this.modelValue,
      name: `${this.type}rating`,
    }
  },
  watch: {
    modelValue(newVal) {
      this.localRating = newVal
    },
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', Number(event.target.value))
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
