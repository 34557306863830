<template>
  <form class="client_info__container">
    <div class="signature-wrapper" :class="{'not-valid-signature': emptySignature}">
      <signature-component
        v-if="signatureShowConditions"
        ref="signatureComponent"
        :changed-signature="handleChangedSignature"
      ></signature-component>
      <div v-if="emptySignature" class="not-valid-signature-text">
        <span>Be sure to leave a signature</span>
      </div>
    </div>
    <div v-if="orderData.signature !== null" class="signature-img-block">
      <img :src="orderData.signature?.path" class="signature-img" alt="">
    </div>
    <div v-if="orderData.instructions.pharmacy" style="width: 100%; margin-top: 10px;">
      <h5 class="info_title form__input__title">Pharmacy Special Instruction:</h5>
      <p style="margin-bottom: 10px; font-size: 14px; color: #757575">{{ orderData.instructions.pharmacy }}</p>
    </div>
    <h5 class="info_title form__input__title">Recipient’s Special Instructions:</h5>
    <template v-if="deliveringState.state !== 'non-confirmed-delivering'">
      <textarea
        v-model="newSpecialInstruction"
        title="Recipient’s Special Instructions"
        placeholder="Add special instruction"
        class="form__input__field_text form__input__field_textarea"
        resize="none"
        style="margin-bottom: 10px;"
      ></textarea>
      <t-button
        v-if="!cantUpdateSpIn"
        :class="'success'"
        type="button"
        @click="updateSpecialInstruction"
      >
        Update
      </t-button>
    </template>
    <template v-else>
      <textarea
        v-model="orderData.instructions.recipient"
        title="Recipient’s Special Instructions"
        placeholder="Add special instruction"
        class="form__input__field_text form__input__field_textarea"
        resize="none"
        style="margin-bottom: 10px;"
      ></textarea>
      <p v-if="orderData.deliver.approve === 'signlink'" class="sign-disclaimer">
        Driver will attempt face-to-face
        delivery. If nobody is home, driver will leave the package by the door and take a picture.
      </p>
      <t-button :class="'success'" :disabled="loading" @click="showDeliveredConfirm">Confirm delivery</t-button>
    </template>
  </form>
  <t-confirm
    :is-visible="showConfirm"
    :ok-callback="onFormSubmit"
    @close="showConfirm = false"
  >
    <template #header>Are you sure?</template>
    <template #body>
      I confirm that information I have provided is correct and accurate
    </template>
  </t-confirm>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import { useToast } from 'vue-toastification'
import { confirmDelivery, getOrderInfo, updateSpecialInstruction } from '@/services/orderService'
import TButton from '@/components/UI/t-button.vue'
import { apiUrl, commUrl } from '@/config'
import TConfirm from '@/components/UI/t-confirm.vue'
import SignatureComponent from './signature-component.vue'

export default {
  name: 'confirmation-form',
  components: {
    SignatureComponent,
    TButton,
    TConfirm,
  },
  data() {
    return {
      emptySignature: false,
      loading: false,
      showConfirm: false,
      newSpecialInstruction: '',
    }
  },
  computed: {
    ...mapGetters(['orderData', 'deliveringState']),
    signatureShowConditions() {
      if (this.deliveringState.state !== 'non-confirmed-delivering') {
        return false
      }
      if (this.orderData.deliver.approve === 'signlink' || this.orderData.deliver.approve === 'nosign') {
        if (this.orderData.signature === null) {
          return true
        }
      }

      return false
    },
    cantUpdateSpIn() {
      return (this.newSpecialInstruction === this.orderData.instructions.recipient)
        || (this.newSpecialInstruction === '' && this.orderData.instructions.recipient === null)
        || (this.newSpecialInstruction === null && this.orderData.instructions.recipient === '')
    },
  },
  mounted() {
    this.newSpecialInstruction = this.orderData.instructions.recipient
  },
  methods: {
    base64ToBlob(base64, mimeType) {
      const bytes = atob(base64.split(',')[1])
      const array = new Uint8Array(bytes.length)

      for (let i = 0; i < bytes.length; i++) {
        array[i] = bytes.charCodeAt(i)
      }

      return new Blob([array], { type: mimeType })
    },
    createFile(blob) {
      return new File(
        [blob],
        `${this.orderData.uid}_signature.svg`,
        { type: 'image/svg' },
      )
    },
    setFormData(file, prefix, hash) {
      const fd = new FormData()
      fd.append('file', file)
      fd.append('prefix', prefix)
      fd.append('hash', hash)

      return fd
    },
    readFileAsArrayBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsArrayBuffer(file)
      })
    },
    async getFileHash(file) {
      const arrayBuffer = await this.readFileAsArrayBuffer(file)
      const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer)
      return CryptoJS.MD5(wordArray)
        .toString()
    },
    async uploadSignature() {
      if (this.$refs.signatureComponent) {
        const signatureBase64 = await this.$refs.signatureComponent.getSignature()

        if (signatureBase64) {
          const signatureBlob = this.base64ToBlob(signatureBase64, 'image/png')
          const signatureFile = this.createFile(signatureBlob)

          const hashMd5 = await this.getFileHash(signatureFile)

          const formData = this.setFormData(signatureFile, `${this.orderData.uid}_signature`, hashMd5)

          const response = await axios.post(`${commUrl}upload-file`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          return {
            response,
            hash: hashMd5,
          }
        }
      }

      return null
    },
    showDeliveredConfirm(e) {
      e.preventDefault()

      const isSignatureEmpty = this.$refs.signatureComponent && this.$refs.signatureComponent.isEmptySignature()
      const isSignLink = this.orderData.deliver.approve === 'signlink'

      if (isSignatureEmpty && isSignLink) {
        this.emptySignature = true
      } else {
        this.showConfirm = true
      }
    },
    handleChangedSignature() {
      this.emptySignature = false
    },
    async onFormSubmit() {
      this.$store.commit('setLoaderVisible', true)
      const toast = useToast()

      this.loading = true

      let signatureLink = null
      let signatureHash = null

      const signatureUpload = await this.uploadSignature()

      if (signatureUpload && signatureUpload.response.status === 200) {
        signatureLink = signatureUpload.response.data.link
        signatureHash = signatureUpload.hash
      }

      const userId = this.orderData.client?.uid
      const dispatcherId = this.orderData.dispatcher?.uid
      const driverId = this.orderData.driver?.uid

      const deliveryConfirmationData = {
        recipient_instruction: this.orderData.instructions.recipient,
        signature_link: signatureLink,
        signature_hash: signatureHash,
        user_id: userId,
        driver_id: driverId,
        dispatcher_id: dispatcherId,
      }

      const response = await confirmDelivery(this.orderData.uid, deliveryConfirmationData)

      if (response.success) {
        toast.success(response.success)
        this.handleDelay(() => window.location.reload())
      } else if (response.error) {
        toast.error(response.error)
        this.handleDelay(() => {
          this.loading = false
        })
      }
      this.$store.commit('setLoaderVisible', false)
    },
    async updateSpecialInstruction() {
      this.$store.commit('setLoaderVisible', true)
      const toast = useToast()
      const response = await updateSpecialInstruction(this.orderData.uid, { recipient_instruction: this.newSpecialInstruction })
      if (response.success) {
        toast.success(response.success)
        const { order } = await getOrderInfo(this.orderData.uid)
        this.$store.commit('setOrderData', order)
      } else {
        window.location.reload()
      }
      this.$store.commit('setLoaderVisible', false)
    },
    handleDelay(callback) {
      setTimeout(() => {
        callback()
      }, 1000)
    },
  },
}
</script>
<style lang="scss" scoped>
.signature-img-block {
  width: 100%;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .signature-img {
    width: 100%;
    max-width: 140px;
  }
}

.sign-disclaimer {
  text-align: center;
  font-size: 14px;
}
</style>
