<template>
  <div class="client_info__container delivery__condition delivery__condition--face">
    <p class="icon delivery__condition__warning ">18+</p>
    <h3 class="delivery__condition__title">
      <i class="icon delivery__condition__title__icon">
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.286 5.398l2.136.784-1.777 4.837c-.166.455-1.068 1.878-1.695 2.169l-.153.415a.261.261 0 01-.334.155.26.26 0 01-.154-.334l.144-.393c-.319-.619-.081-2.416.058-2.796l1.775-4.837zM14.376.28c-.587-.215-1.274.23-1.47.767l-1.288 3.505 2.136.783 1.285-3.504c.162-.44-.074-1.335-.662-1.55zm-3.864 4.937a.522.522 0 00.67-.308l1.357-3.661a.52.52 0 10-.977-.362l-1.357 3.661c-.1.27.037.57.307.67zm-2.185 6.865c-.14-.132-.315-.297-.598-.423-.232-.104-.459-.109-.625-.113h-.057c-.103-.284-.313-.463-.629-.532-.417-.089-.75.108-.98.26.02-.116.053-.255.078-.355.08-.321.162-.654.101-.965a.555.555 0 00-.506-.446c-.646-.037-1.173.31-1.638.625-.488.33-.801.526-1.113.415-.38-.135-.418-1.164-.235-2.08.163-.826.468-2.363 1.247-3.061.263-.235.643-.49.955-.366.368.144.671.75.739 1.47a.554.554 0 101.103-.104c-.11-1.174-.66-2.093-1.437-2.398-.464-.18-1.204-.23-2.1.572-.974.873-1.334 2.353-1.595 3.671-.131.658-.45 2.842.951 3.34.853.305 1.546-.165 2.103-.54.102-.069.203-.137.3-.197-.092.402-.15.832.093 1.194.361.544.873.604 1.519.178.025-.016.054-.035.08-.054.06.125.166.263.359.358.232.114.465.12.635.124a.876.876 0 01.201.017c.105.046.166.102.286.215.09.085.184.173.304.264a.555.555 0 00.664-.887c-.08-.062-.144-.123-.205-.182z" fill="#29B6FF"/></svg>
      </i>
      <span class="delivery__condition__text">Face-to-Face Signature Required</span>
    </h3>
    <p class="delivery__condition__desc">
      Signature is required by any person who lives with the patient. Driver will
      take a picture of the patient’s door.
    </p>
  </div>
</template>
<script>
export default {
  name: 'face2-face-required',
}
</script>
