<template>
  <div class="form__input form-select" :class="{'form__input--small': small}">
    <h5 class="info_title form__input__title" :class="{'info_title--small': smallLabel}">{{ label }}</h5>
    <div class="form__input__field_wrapper">
      <select v-model="customValue" :class="{'error-input' : error}" class="form__input__field_text">
        <option v-for="(item, i) in items" :key="i" :value="item">{{ item }}</option>
      </select>
      <p class="form__input__placeholder">{{ placeholder }}</p>
    </div>
  </div>
</template>
<script>
export default {
//   inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },

    smallLabel: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    customValue: {
      get() { return this.modelValue },
      set(v) { this.$emit('update:modelValue', v) },
    },
  },
}
</script>

<style lang="scss" scoped>
.form-select {
  select {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('@/assets/img/down-arrow.png');
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
  }
}
</style>
