<template>
  <div class="client_info__container delivery__condition delivery__condition--optional">
    <p class="icon delivery__condition__warning ">18+</p>
    <h3 class="delivery__condition__title">
      <i class="icon delivery__condition__title__icon">
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.62 12.116h-1.367V3.88a.703.703 0 00-.202-.483.681.681 0 00-.483-.202H5.095a.691.691 0 00-.487.202.7.7 0 00-.198.483V8h2.042a1.373 1.373 0 011.367 1.373v3.43h4.8a.344.344 0 100-.687zM9.882 8.344a.344.344 0 11-.006-.688.344.344 0 01.006.688z" fill="#27CAA3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7.41 9H4.684A.685.685 0 004 9.688v2.746a.685.685 0 00.685.685h3.409V9.688A.685.685 0 007.409 9zm-1.117 1.717h-.499a.344.344 0 010-.685h.512a.345.345 0 01.376.342.344.344 0 01-.376.343h-.013z" fill="#27CAA3"/></svg>
      </i>
      <span class="delivery__condition__text">Signature Optional</span>
    </h3>
    <p class="delivery__condition__desc">
      Driver will attempt face-to-face delivery. If nobody is home,
      driver will leave the package by the door and take a picture.
    </p>
  </div>
</template>
<script>
export default {
  name: 'signature-optional',
}
</script>
