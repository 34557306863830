<template>
  <h2 class="section__title">{{ callTitle }}</h2>
  <p class="delivery__feedback__desc">
    You may now close this page. If you have any questions, please feel free to
    call us at <br><a href="tel:19294703999" style="text-decoration: underline;">(929) 470-3999.</a>
  </p>
  <ul class="feedback__mark">
    <li class="feedback__mark__item">
      <div class="rating-avatar">
        <div
          class="rating-avatar-bg"
          :style="{'background-image': 'url(' + dispatcherIcon + ')'}"
        ></div>
      </div>
      <p class="feedback__mark__role">Call Rating</p>
      <div class="feedback__mark__stars_wrapper">
        <input
          v-for="star in maxStars"
          :id="name + 'Star' + star"
          :key="'input-' + star"
          v-model="callFeedbackData.rate"
          type="radio"
          :name="name"
          :value="star"
          :checked="callFeedbackData.rate === star"
          class="feedback__mark__radio"
        >
        <label
          v-for="star in maxStars"
          :key="'label-' + star"
          :for="name + 'Star' + star"
          class="icon feedback__mark__star"
        >
          <img :src="callFeedbackData.rate >= star ? activeStar : disabledStar" :alt="'Star ' + star">
        </label>
      </div>
    </li>
  </ul>
  <div class="client_info__container form">
    <div class="form__input">
      <h5 class="info_title form__input__title">Please leave your comments:</h5>
      <div class="form__input__field_wrapper">
        <textarea
          v-model="callFeedbackData.comment"
          class="form__input__field_text form__input__field_textarea"
          :class="badRating ? 'textarea-error' : ''"
          resize="none"
          placeholder=" "
        ></textarea>
        <p class="form__input__placeholder">
          Write down if you liked everything, your expectations and impressions
        </p>
      </div>
      <p v-if="badRating" class="error-label">If you set a bad rate, so please explain what is wrong?</p>
    </div>
    <t-button
      :class="'success'"
      :disabled="feedbackInvalid"
      style="margin-top: 10px"
      @click="sendFeedback()"
    >
      <i class="icon form__btn__icon form__btn__icon--tg">
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19.324.677a1.031 1.031 0 00-1.096-.235L1.04 6.948h-.005a1.031 1.031 0 00.043 1.941l.018.005 5.9 2.52a.687.687 0 00.667-.155l9.469-8.822a.304.304 0 11.43.43L8.737 12.33a.688.688 0 00-.154.668l2.52 5.904.008.024c.137.398.486.679.906.698h.043a1.058 1.058 0 00.989-.665l6.504-17.183a1.031 1.031 0 00-.23-1.1v.001z" fill="#fff"/></svg>
      </i>
      <span>Send</span>
    </t-button>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import { mapGetters } from 'vuex'
import TButton from '@/components/UI/t-button.vue'
import dispatcherIcon from '@/assets/img/dispatcher.png'
import activeStar from '@/assets/img/star.svg'
import disabledStar from '@/assets/img/star_disable.svg'
import { storeCall } from '@/services/callService'

export default {
  name: 'call-feedback-form',
  components: {
    TButton,
  },
  data() {
    return {
      dispatcherIcon,
      maxStars: 5,
      name: 'call-rating',
      activeStar,
      disabledStar,
    }
  },
  computed: {
    ...mapGetters(['callData', 'callFeedbackData']),
    callTitle() {
      if (this.callFeedbackData.rate > 0) {
        return 'Thanks for your feedback!'
      }

      return 'Please rate our call!'
    },
    feedbackInvalid() {
      console.log(this.callData)
      if (this.callFeedbackData.rate === 0) {
        return true
      }
      if (this.callFeedbackData.rate > 0 && this.callFeedbackData.rate <= 4) {
        if (this.callFeedbackData.comment.length < 1) {
          return true
        }
      }

      return false
    },
    badRating() {
      if (this.callFeedbackData.rate > 0 && this.callFeedbackData.rate <= 4) {
        if (this.callFeedbackData.comment.length < 1) {
          return true
        }
      }

      return false
    },
  },
  methods: {
    async sendFeedback() {
      const toast = useToast()
      this.$store.commit('setLoaderVisible', true)

      const request = {
        hash: this.callData.created_at,
        id: this.callData.uid,
        rating: this.callFeedbackData.rate,
        comment: this.callFeedbackData.comment,
        is_finalized: true,
      }
      const response = await storeCall(request)
      if (response.success) {
        toast.success(response.success)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }

      if (response.error) {
        toast.error(response.error)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.textarea-error {
  border-color: #E64646;
}
.error-label {
  color: #E64646;
}
</style>
