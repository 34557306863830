<template>
  <div v-if="isVisible" class="modal" @click="handleBackgroundClick">
    <div class="modal-content" @click="handleModalContentClick">
      <div class="modal-body">
        <qrcode-vue :value="qrNumber" :size="150"></qrcode-vue>
        <p>To receive your order, show the QR code to the driver or call the code <strong>{{ qrNumber }}</strong></p>
        <t-button class="btn outline" style="padding: 0; line-height: 40px" @click="close()">Cancel</t-button>
      </div>
    </div>
  </div>
</template>

<script>

import QrcodeVue from 'qrcode.vue'
import TButton from '@/components/UI/t-button.vue'

export default {
  name: 'qr-popup',
  components: {
    TButton,
    QrcodeVue,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    qrNumber: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
  methods: {
    handleBackgroundClick() {
      this.close()
    },
    handleModalContentClick(event) {
      event.stopPropagation()
    },
    close() {
      this.swiperThumbs = null
      this.swiper = null
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;

  .modal-content {
    position: relative;
    width: 95%;
    max-width: 400px;
    z-index: 99999;
    background-color: #ffffff;
    padding: 40px 80px 30px;
    border-radius: 20px;

    .close-btn {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.6);
      font-size: 30px;
      padding: 0 10px 4px;
      color: #000000;
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        width: 100%;
        max-width: 245px;
        text-align: center;
        margin-top: 15px;
      }

      .btn {
        width: 100%;
        max-width: 130px;
        margin-top: 15px;
        line-height: 47px!important;
      }
    }
  }
}
</style>
