<template>
  <app-header v-if="callData.rate < 1" :hide-label="true"></app-header>
  <div class="wrapper-section">
    <section class="section delivered-section" style="padding-top: 13px">
      <div class="section__content">
        <rate-accepted v-if="callData.rate > 0"></rate-accepted>
        <call-feedback-form v-else></call-feedback-form>
      </div>
    </section>
  </div>
</template>

<script>
import CallFeedbackForm from '@/components/CallFeedbackView/call-feedback-form.vue'
import { mapGetters } from 'vuex'
import RateAccepted from '@/components/CallFeedbackView/rate-accepted.vue'
import AppHeader from '@/components/AppHeader/index.vue'

export default {
  name: 'call-feedback-view',
  components: {
    RateAccepted,
    CallFeedbackForm,
    AppHeader,
  },
  computed: {
    ...mapGetters(['callData']),
  },
}
</script>
