<template>
  <face2-face-i-d-required v-if="orderData.deliver.approve === 'idrequired'"></face2-face-i-d-required>
  <face2-face-required v-if="orderData.deliver.approve === 'face2face'"></face2-face-required>
  <face2-face-not-required v-if="orderData.deliver.approve === 'inperson'"></face2-face-not-required>
  <online-signature v-if="orderData.deliver.approve === 'signlink'"></online-signature>
  <signature-optional v-if="orderData.deliver.approve === 'nosign'"></signature-optional>
  <non-contact-delivery v-if="orderData.deliver.approve === 'noask'"></non-contact-delivery>
</template>

<script>
import { mapGetters } from 'vuex'
import Face2FaceIDRequired from './Face2FaceIDRequired.vue'
import Face2FaceNotRequired from './Face2FaceNotRequired.vue'
import Face2FaceRequired from './Face2FaceRequired.vue'
import NonContactDelivery from './NonContactDelivery.vue'
import OnlineSignature from './OnlineSignature.vue'
import SignatureOptional from './SignatureOptional.vue'

// idrequired (Face2FaceIDRequired)
// face2face (Face2FaceRequired)
// inperson (Face2FaceNotRequired)
// signlink (OnlineSignature)
// nosign (SignatureOptional)
// noask (NonContactDelivery)

export default {
  name: 'approve-type',
  components: {
    SignatureOptional,
    OnlineSignature,
    NonContactDelivery,
    Face2FaceRequired,
    Face2FaceNotRequired,
    Face2FaceIDRequired,
  },
  computed: {
    ...mapGetters(['orderData']),
  },
}
</script>
