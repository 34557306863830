<template>
  <div v-if="isVisible" class="modal" @click="handleBackgroundClick">
    <div class="modal-content" @click="handleModalContentClick">
      <div class="modal-header">
        <slot name="header"></slot>
      </div>
      <div class="modal-body">
        <slot name="body"></slot>
      </div>
      <div class="modal-footer">
        <t-button :class="'success'" style="line-height: 50px; font-size: 18px" @click="yes">Yes, I confirm</t-button>
        <t-button :class="'error'" style="line-height: 48px;  font-size: 17px" @click="close()">No</t-button>
      </div>
    </div>
  </div>
</template>

<script>
import TButton from '@/components/UI/t-button.vue'

export default {
  name: 't-confirm',
  components: { TButton },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    okCallback: {
      type: Function,
      required: true,
    },
  },
  emits: ['close'],
  methods: {
    handleBackgroundClick() {
      this.$emit('close')
    },
    handleModalContentClick(event) {
      event.stopPropagation()
    },
    close() {
      this.$emit('close')
    },
    yes() {
      this.$emit('close')
      this.okCallback()
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-body {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.modal-content {
  background: white;
  padding: 24px;
  border-radius: 20px;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  max-width: 400px;
}

.modal-footer {
  margin-top: 12px;

  &.success {
    margin-bottom: 8px;
  }

  &.error {
  }
}

.modal-header {
  margin-bottom: 12px;
  text-align: center;
  color: #21979E;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%
}
</style>
