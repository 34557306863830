<template>
  <c-input
    ref="field"
    v-bind="$attrs"
    v-model="computedFormatted"
    @focus="focus = true"
    @blur="focus = false"
  >
  </c-input>
</template>
<script>
import { computed } from 'vue'
import CInput from '@/components/UI/form/c-input.vue'

// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import { dashUrl } from '@/config'
import { nGps } from '@/utils/Address'
import { awaitCondition } from '@/helpers'
import { mapGetters } from 'vuex'

export default {
  name: 'c-address-full',
  components: {
    CInput,
  },
  props: {
    address: {
      type: Object,
      required: true,
    },
    origin: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:address'],
  setup(props, { emit }) {
    const computedAddress = computed({
      get() { return props.address },
      set(v) { emit('update:address', v) },
    })
    return { computedAddress }
  },
  data: () => ({
    focus: false,
    google: false,
    suggestions: [],
    tmp: '',
  }),
  computed: {
    ...mapGetters(['orderData']),
    formattedAddress: {
      get() {
        const { address } = this.computedAddress
        const city = this.computedAddress.city || this.origin?.city
        const state = this.computedAddress.state || this.origin?.state
        const zip = this.computedAddress.zip || this.origin?.zip
        const text = `${address}, ${city}, ${state} ${zip}`
        if (!text.replaceAll(' ', '').replaceAll(',', '')) {
          return ''
        }
        return text
      },
      set(v) { this.parseAddress(v) },
    },
    computedFormatted: {
      get() { return this.formattedAddress },
      set(v) {
        if (!this.google) {
          this.setFormatted(v)
        }
        this.tmp = v
      },
    },
  },
  watch: {
    formattedAddress(input) {
      if (!input) return
      if (this.focus) {
        // тут пробуем подобрать данные по google
      } else {
        this.computedAddress.lat = null
        this.computedAddress.lng = null
        this.computedAddress.source = 'Manual'
        _.debounce(this.detectGeo, 3000)()
      }
    },
    focus(v) {
      if (!v && this.tmp !== this.formattedAddress) {
        // this.formattedAddress = this.tmp
      }
    },
  },
  mounted() {
    this.createGoogle()
  },
  methods: {
    setFormatted: _.debounce(function setFormatted(v) { this._setFormatted(v) }, 1000),
    _setFormatted(v) {
      this.formattedAddress = v
    },

    createGoogle() {
      awaitCondition(() => !!window.google && !!this.$refs.field && !!this.$refs.field.$refs.input)
        .then(() => {
          // console.log(this.$refs.field)
          this.google = true
          const autocomplete = new window.google.maps.places.Autocomplete(this.$refs.field.$refs.input, {})
          window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            this.fillInAddressGoogle(autocomplete.getPlace())
          })

          // console.log(this.$refs.field.$refs.input)
          // this.$refs.field.$refs.input.change()
        })
    },

    fillInAddressGoogle(place) {
      // console.log(['-fillInAddressGoogle'])
      // https://github.com/googlemaps/js-samples/blob/c1036e60d2f50056fba1617a1be507b00b6cac5a/dist/samples/places-autocomplete-addressform/docs/index.js#L38-L-1

      // Reset all before...
      this.computedAddress.address = ''
      this.computedAddress.city = ''
      this.computedAddress.zip = ''
      this.computedAddress.state = ''
      this.computedAddress.source = 'Google'
      delete (this.computedAddress.address_json)

      let address = ''
      let postcode = ''

      for (const component of place.address_components) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0]
        switch (componentType) {
          case 'street_number':
            address = `${component.long_name} ${address}`
            this.computedAddress.street_number = component.long_name
            break

          case 'route':
            address += component.short_name
            this.computedAddress.street = component.short_name
            break

          case 'postal_code':
            postcode = `${component.long_name}${postcode}`
            break
            // case 'postal_code_suffix':
            //   postcode = `${postcode}-${component.long_name}`
            //   break
            // case 'locality':
            //   this.computedAddress.city = component.long_name
            //   break
            // case 'sublocality_level_1':
            //   if (!this.computedAddress.city) { this.computedAddress.city = component.long_name }
            //   break
          case 'administrative_area_level_1':
            this.computedAddress.state = component.short_name
            break

            // case 'country':
            //   this.computedAddress.country = component.long_name
            //   break
        }
      }

      this.computedAddress.address = address
      this.computedAddress.zip = postcode

      this.computedAddress.lat = nGps(place.geometry.location.lat())
      this.computedAddress.lng = nGps(place.geometry.location.lng())

      this.computedAddress.adjusted = false

      this.computedAddress.place_id = place.place_id

      if (place.formatted_address) {
        this.parseAddress(place.formatted_address)
      }
      // this.updateRemember()
      // this.setFormatted(this.computedAddress)

      // this.$forceUpdate()
      // this.$nextTick(() => {
      //   // this.checkChanges()
      //   this.setFormatted(this.computedAddress)
      // })
    },
    // fillInAddressLocal(place) {
    //   // console.log(['-fillInAddressLocal'])
    //   this.computedAddress.address = `${place.street_number} ${place.street}`.trim()
    //   this.computedAddress.zip = place.zip

    //   this.computedAddress.street_number = place.street_number
    //   this.computedAddress.street = place.street

    //   this.computedAddress.lat = nGps(place.lat)
    //   this.computedAddress.lng = nGps(place.lng)
    //   this.computedAddress.state = place.state
    //   this.computedAddress.city = place.city

    //   this.computedAddress.adjusted = false

    //   this.computedAddress.place_id = place.place_id

    //   if (place.formatted_address) {
    //     this.parseAddress(place.formatted_address)
    //   }

    //   // this.updateRemember()
    //   // this.setFormatted(this.computedAddress)

    //   // this.$forceUpdate()
    //   // this.$nextTick(() => {
    //   //   // this.checkChanges()
    //   //   this.setFormatted(this.computedAddress)
    //   // })
    // },
    parseAddress(formatted) {
      const [address, city, _state, _zip] = formatted.split(',')
      const [_a, number, street] = (address ?? '').match(/^(\d[^\s]*)?\s*(.*)/)
      const [state, zip] = (_state ?? '').trim().replace(/\s+/, ' ').split(' ')

      this.computedAddress.street_number = String(number ?? '')
      this.computedAddress.street = String(street ?? '')

      this.computedAddress.address = String(address ?? '')
      this.computedAddress.city = String(city ?? '').replace(/^\s+/, '')
      this.computedAddress.state = String(state ?? '').replace(/^\s+/, '')
      this.computedAddress.zip = String(zip ?? _zip ?? '').replace(/\s+$/, '')
    },
    detectGeo() {
      fetch(`${dashUrl}/ajax/PullCommon.php?action=detectGeo`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify({ payment_method_id: result.paymentMethod.id, amount: fee.total, order_id: "<?= $oid ?>"}) // TEMP NOT UPPER FEE
        body: JSON.stringify({
          address: this.computedAddress,
        }),
      })
        .then(result => result.json())
        .then(this.onDetectGeo)
    },
    onDetectGeo({ geo }) {
      this.computedAddress.adjusted = 'no'
      if (geo.lat && geo.lng) {
        this.computedAddress.lat = nGps(geo.lat)
        this.computedAddress.lng = nGps(geo.lng)
      }
    },
  },
}
</script>
