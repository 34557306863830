<template>
  <div :class="mainDivClass">
    <div>
      <i class="icon client_info__icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="8" cy="8" r="7.5" stroke="#1FBEC8"/><path d="M9.667 13H6.333V9.667H3V6.333h3.333V3h3.334v3.333H13v3.334H9.667V13z" fill="#1FBEC8"/></svg>
      </i>
      <h4 class="client_info__title">
        <span v-if="isNew">New </span>
        Delivery From:
      </h4>
      <p class="client_info__value">{{ deliveryFrom }}</p>
    </div>
    <div
      v-if="logo"
      class="pharmacy-logo"
    >
      <div class="bg" :style="'background-image: url(\'' + logoUrl + '\');'"></div>
    </div>
  </div>
</template>

<script>
import pharmacyIcon from '@/assets/img/pharmacy.png'

export default {
  name: 'delivery-from',
  props: {
    long: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pharmacyIcon,
    }
  },
  computed: {
    deliveryFrom() {
      return this.$store.getters.orderData.client.business_name
    },
    mainDivClass() {
      return {
        'logo-class': this.logo !== '',
        client_info: true,
        'client_info--long': this.long,
      }
    },
    logoUrl() {
      if (this.logo === '' || this.logo === '/assets/common/img/temp/avatars/1.jpg') {
        return this.pharmacyIcon
      }
      return this.logo
    },
  },
}
</script>

<style lang="scss" scoped>
.pharmacy-logo {
  .bg {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
  }
}

.logo-class {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
