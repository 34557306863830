<template>
  <pending-back-to-pharmacy v-if="deliveringState.subState === 'pending-back-to-pharmacy'"></pending-back-to-pharmacy>
  <open-reschedule v-if="deliveringState.subState === 'open'"></open-reschedule>
</template>
<script>
import PendingBackToPharmacy from '@/components/MainPage/Returned/PendingBackToPharmacy/index.vue'
import OpenReschedule from '@/components/MainPage/Returned/OpenReschedule/index.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'returned-component',
  components: { OpenReschedule, PendingBackToPharmacy },
  computed: {
    ...mapGetters(['deliveringState']),
  },
}
</script>
