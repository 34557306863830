<template>
  <h2 class="section__title section__title--error">{{ text }}</h2>
</template>

<script>
export default {
  name: 'default-text',
  data() {
    return {
      text: 'Your order has been cancelled and sent back to the pharmacy',
    }
  },
}
</script>
