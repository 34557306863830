<template>
  <div class="delivered--info--from--container">
    <h2 class="section__title" style="color: #000000">{{ text }}</h2>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatTimestamp } from '@/helpers'

export default {
  name: 'delivered-order',
  computed: {
    ...mapGetters(['orderData']),
    text() {
      let text = 'Delivered '
      if (this.orderData.deliver?.time_delivered) {
        text += formatTimestamp(this.orderData.deliver.time_delivered)
      }

      return text
    },
  },
}
</script>
