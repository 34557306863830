import axios from 'axios'

export const SourceManual = 'Manual'
export const SourceGoogle = 'Google'
export const SourceBing = 'Bing'
export const SourceGoogleStock = 'Google stock'

export async function confirmChecker(outAddress, search) {
  const [fInput, fSearch] = await axios.post(
    '/ajax/PullAddress.php',
    { address: [outAddress.address, search.split(',')[0]] },
    { params: { action: 'formattingAddress' } },
  ).then(({ data: { address } }) => address)

  if (fInput.toLowerCase() !== fSearch.toLowerCase()) {
    // dd
    return window.getConfirm(`<h4>Address changed</h4><div>Entered Address: <b>${search.split(',')[0]}</b><br> Validated Address: <b>${outAddress.address}</b></div>`, {
      buttons: {
        apply: { label: 'ACCEPT CHANGES', color: 'success' },
        manual: { label: 'My address is correct ', color: 'red', class: 'ml-2' },
        cancel: { label: 'Cancel', color: 'grey lighten-1', class: 'ml-2' },
      },
    })
  }
  return Promise.resolve(true)
}

export function resetAddress(input) {
  input.address = ''
  input.zip = ''
  input.street_number = ''
  input.street = ''
  input.lat = ''
  input.lng = ''
  input.state = ''
  input.city = ''
  input.place_id = ''
  input.formatted_address = ''
  input.adjusted = false
}

export function nGps(v) {
  const tmp = parseFloat(v, 10)
  return ((((tmp % 360) + 540) % 360) - 180).toFixed(6)
}
